import * as React from 'react'
import { DataTh, SortControl } from '../../../components/DataTable'
import { useTrafficReportDimensionChoices } from '../../../util/hooks/cookie/useTrafficReportDimensionChoices'
import { getDimensionBackgroundColorByOrder } from '../utils'
import { DIMENSION_TABLE_CONFIG } from './'
import { TrafficReportContext } from '../../../contexts/TrafficReportContext'

/**
 * 流入レポートのディメンションヘッダーをレンダリングする
 */
export const DimensionHeaders = () => {
  const { dimensionChoices } = useTrafficReportDimensionChoices()
  const { getSortIcon, setSortValues } = React.useContext(TrafficReportContext)

  return (
    <>
      {dimensionChoices.map((dimensionId, index) => (
        <DataTh
          key={dimensionId}
          fillWidth
          minWidth="200px"
          backgroundColor={getDimensionBackgroundColorByOrder(index)}
        >
          <SortControl
            sortKey={DIMENSION_TABLE_CONFIG[dimensionId].sortKey}
            sortIcon={getSortIcon(DIMENSION_TABLE_CONFIG[dimensionId].sortKey)}
            onSortClick={setSortValues}
            textAlign="left"
          >
            {DIMENSION_TABLE_CONFIG[dimensionId].label}
          </SortControl>
        </DataTh>
      ))}
    </>
  )
}
