import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Role, RoleLabel } from '../../util/Authority'
import { AddMemberButton } from '../common/Button'
import { PopupMenu } from '../common/PopupMenu'
import { Settings } from '@styled-icons/material/Settings'

export interface MemberState {
  readonly id: number
  readonly userName: string
  readonly email: string
  readonly role: number
  readonly roleName: string
}

export interface AdminState {
  readonly id: number
  readonly name: string
  readonly email: string
}

interface Props {
  readonly members: MemberState[]
  readonly admins: AdminState[]
  readonly canAddProjectMember?: boolean
  readonly canChangeProjectMember?: boolean
  readonly canDeleteProjectMember?: boolean
  readonly canViewTeamSettings?: boolean
  readonly onAdd?: () => void
  readonly onEdit?: (id: number) => void
  readonly onDelete?: (id: number) => void
}

export function MemberList(props: Props) {
  const {
    members,
    admins,
    canAddProjectMember,
    canChangeProjectMember,
    canDeleteProjectMember,
    canViewTeamSettings,
    onAdd,
    onEdit,
    onDelete,
  } = props
  const [state, setState] = React.useState({ id: 0, opened: false })
  const onClick = (id: number) => {
    setState({ ...state, id: id, opened: true })
  }
  const popupClose = () => {
    setState({ ...state, id: 0, opened: false })
  }
  return (
    <Container>
      {canAddProjectMember && (
        <div style={{ marginTop: '1rem' }}>
          <AddMemberButton onClick={onAdd}>メンバーを招待</AddMemberButton>
        </div>
      )}
      {members.length === 0 ? (
        <div style={{ margin: '1.5rem 0' }}>メンバーが登録されていません</div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Item style={{ paddingBottom: '0.5rem' }}>
            <Box className="title" style={{ width: '250px' }}>
              ユーザー名
            </Box>
            <Box className="title" style={{ width: '350px' }}>
              メールアドレス
            </Box>
            <Box className="title">役割</Box>
          </Item>
          {members.map((member) => {
            return (
              <Item key={member.id}>
                <Box style={{ width: '250px' }}>{member.userName}</Box>
                <Box style={{ width: '350px' }}>{member.email}</Box>
                {member.role === Role.Project.admin && <Box style={{ width: '200px' }}>{RoleLabel.Project.admin}</Box>}
                {member.role === Role.Project.member && (
                  <Box style={{ width: '200px' }}>{RoleLabel.Project.member}</Box>
                )}
                {(canChangeProjectMember || canDeleteProjectMember) && (
                  <SettingBox>
                    <SettingIcon onClick={() => onClick(member.id)} />
                    {state.opened && state.id === member.id && (
                      <PopupMenu
                        onClose={popupClose}
                        style={{
                          right: '20px',
                          bottom: canChangeProjectMember && canDeleteProjectMember ? '-25px' : '-10px',
                          width: '120px',
                        }}
                      >
                        <Links>
                          {canChangeProjectMember && (
                            <DesignedLink onClick={() => onEdit && onEdit(member.id)}>権限の変更</DesignedLink>
                          )}
                          {canDeleteProjectMember && (
                            <DesignedLink onClick={() => onDelete && onDelete(member.id)}>メンバー削除</DesignedLink>
                          )}
                        </Links>
                      </PopupMenu>
                    )}
                  </SettingBox>
                )}
              </Item>
            )
          })}
        </div>
      )}

      {/* 組織管理者リスト */}
      <div style={{ fontWeight: 'bold', fontSize: '1.2rem', margin: '3rem 0 1rem' }}>組織管理者</div>
      {admins.length === 0 ? (
        <div>組織管理者が存在しません</div>
      ) : (
        <AdminBox>
          <div>「組織管理者」は自動で全プロジェクトの「プロジェクト管理者」になります</div>
          {canViewTeamSettings && (
            <Link href="/admin/member" target="_blank" rel="noopener" style={{ margin: '0.5rem 0' }}>
              組織管理者追加画面はこちら
            </Link>
          )}
          <Item style={{ paddingBottom: '0.5rem' }}>
            <Box className="title" style={{ width: '250px' }}>
              組織管理者名
            </Box>
            <Box className="title">メールアドレス</Box>
          </Item>
          {admins.map((admin) => {
            return (
              <Item key={admin.id}>
                <Box style={{ width: '250px' }}>{admin.name}</Box>
                <Box>{admin.email}</Box>
              </Item>
            )
          })}
        </AdminBox>
      )}
    </Container>
  )
}

const Container = styled.div``

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  height: 100%;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${colors.gray300};
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;

  & + & {
    margin-left: 1.5rem;
  }

  &.title {
    color: ${colors.gray500};
  }
`

const SettingBox = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 1rem;
`

const SettingIcon = styled(Settings).attrs({
  size: '24',
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
`

const DesignedLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 1rem;
  color: ${colors.link.base};
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.link.hover};
    font-weight: 600;
    background-color: ${colors.gray200};
  }

  & + & {
    margin-top: 0.1rem;
  }
`

const Link = styled.a`
  color: ${colors.link.base};
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.link.hover};
    font-weight: 600;
  }

  &:visited {
    color: ${colors.link.visited};
  }
`

const AdminBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.gray100};
  padding: 2rem 1rem;
`
