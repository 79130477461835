import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { ContextType, useContextState } from './state'
import { matchSortKeyAndScopeType, isSortKeyValidForDimensions } from '../../util/hooks/api/TrafficReport/utils'
import { SORT_KEYS } from '../../util/hooks/api/TrafficReport/constants'
import { SCOPE_TYPE, useScopeType } from '../../util/hooks/useScopeType'
import {
  getSearchParamsDimensionChoices,
  useTrafficReportDimensionChoices,
} from '../../util/hooks/cookie/useTrafficReportDimensionChoices'
import { CustomFilterContext } from '../CustomFilterContext'

export const TrafficReportContext = React.createContext<ContextType>(null as any)

interface Props extends RouteComponentProps {
  readonly children?: React.ReactNode | React.ReactNode[]
}

export function TrafficReportProvider({ children }: Props) {
  const { isReportParamsChanged } = React.useContext(CustomFilterContext)

  const contextValue = useContextState()
  const { sortKey, setSortKey, resetPagerIndex } = contextValue
  const { scopeType } = useScopeType()
  const { dimensionChoices, setDimensionChoices } = useTrafficReportDimensionChoices()

  React.useEffect(() => {
    // 流入レポート->ページレポート遷移後にレポート共通パラメータがリセットされた場合、
    // 一覧件数や順番が変わる可能性があるためページャーをリセットする
    if (isReportParamsChanged) {
      resetPagerIndex()
    }
  }, [isReportParamsChanged])

  // URLパラメータからdimensionChoicesの初期値を読み込む
  React.useEffect(() => {
    const urlDimensions = getSearchParamsDimensionChoices()
    if (urlDimensions !== null) {
      setDimensionChoices(urlDimensions)
    }
  }, [])

  // 集計単位の"ユーザー/セッション"とソートキーの"ユーザー数/セッション数"を合わせる
  if (!matchSortKeyAndScopeType(sortKey, scopeType)) {
    const newSortKey = scopeType === SCOPE_TYPE.USER ? SORT_KEYS.USER_COUNT : SORT_KEYS.SESSION_COUNT
    setSortKey(newSortKey)
  }

  // 選択されていないディメンションでソートされている場合、"ユーザー数/セッション数"でソートするようにリセット
  // NOTE: URLパラメータチェックのuseEffectより下に記載する必要があった。dimensionChoicesを利用しているため。
  if (!isSortKeyValidForDimensions(sortKey, dimensionChoices)) {
    const newSortKey = scopeType === SCOPE_TYPE.USER ? SORT_KEYS.USER_COUNT : SORT_KEYS.SESSION_COUNT
    setSortKey(newSortKey)
  }

  return <TrafficReportContext.Provider value={contextValue}>{children}</TrafficReportContext.Provider>
}
