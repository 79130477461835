import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import { CUSTOM_DIMENSION_VALUE_REPORT_QUERY_KEY } from '../constants'
import { CustomDimensionData } from '../../../Response'
import { CalenderState } from '../../../../components/common/DayPickerRange'
import { DeviceType, getDeviceLayoutNumber, useDeviceType } from '../../useDeviceType'
import { CustomFilterState } from '../Filter/types'
import { ScopeType, useScopeType } from '../../useScopeType'
import { ErrorType, request } from '../../../request'
import { getDateStringYMD } from '../../../Date'
import { getScopeTypeApiValue } from '../../../getScopeTypeApiValue'
import { makeReportFilters } from '../../../makeReportFilters'
import { useGoalList } from '../Goal/useGoalList'
import { getScaledPercent } from '../../../getScaledPercent'
import { Percent } from '../../../Parse'
import { useGoalId } from '../../useGoalId'
import { ReportContext } from '../../../../contexts/ReportProvider'
import { CustomFilterContext } from '../../../../contexts/CustomFilterContext'
import { CustomDimensionValueReportContext } from '../../../../contexts/CustomDimentionValueReportContext'
import { convertSortOrder } from './utils'
import { SortKey } from './types'
import { SortIconState } from '../../../../components/common/SortButton'
import { CustomDimensionReportContext } from '../../../../contexts/CustomDimentionReportContext'

export interface CustomDimensionValueReportItemResponse {
  readonly custom_dimension_value: string
  readonly session_count: number
  readonly user_count: number
  readonly goal_count: number
  readonly goal_rate: number
}

export interface CustomDimensionValueReportResponse {
  readonly custom_dimension: CustomDimensionData
  readonly count: number
  readonly results: Array<CustomDimensionValueReportItemResponse>
  readonly all_session_count: number
  readonly all_user_count: number
  readonly all_goal_count: number
  readonly all_goal_rate: number
}

export interface CustomDimensionValueReportItem {
  readonly index: number
  readonly customDimensionValue: string
  readonly sessionCount: number
  readonly sessionScaledPercent: number
  readonly sessionOverallRatio: number
  readonly userCount: number
  readonly userScaledPercent: number
  readonly userOverallRatio: number
  readonly goalCount: number
  readonly goalCountScaledPercent: number
  readonly goalCountOverallRatio: number
  readonly goalRate: number
}

export interface CustomDimensionValueReport {
  readonly name: string
  readonly customDimensionId: number
  readonly count: number
  readonly results: CustomDimensionValueReportItem[]
  readonly allSessionCount: number
  readonly allUserCount: number
  readonly allGoalCount: number
  readonly allGoalRate: number
}

interface Props {
  readonly projectId: number
  readonly customDimensionId: number
  readonly enabled?: boolean
}

export const useCustomDimensionValueReport = ({ projectId, customDimensionId, enabled = true }: Props) => {
  const {
    state: { calenderState },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)
  const {
    state: { searchText, listItemsCount, pagerIndex, cachedCustomDimensionId },
    actions: { resetSearchValues },
  } = React.useContext(CustomDimensionValueReportContext)
  const {
    state: { sortKey, sortIcon },
  } = React.useContext(CustomDimensionReportContext)

  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { goalId } = useGoalId({ projectId })

  const { getGoal } = useGoalList({ projectId })
  const goal = getGoal(goalId)

  const [errorMessage, setErrorMessage] = React.useState('')

  /**
   * 前回と異なるカスタムディメンションが選択された場合は、検索情報をリセットし、IDをキャッシュする
   */
  const isCachedIdUnmatched = customDimensionId !== cachedCustomDimensionId
  React.useEffect(() => {
    if (isCachedIdUnmatched) {
      resetSearchValues(customDimensionId)
    }
  }, [customDimensionId])

  const queryKey = [
    CUSTOM_DIMENSION_VALUE_REPORT_QUERY_KEY,
    {
      customDimensionId,
      calenderState,
      goalId,
      scopeType,
      deviceType,
      customFilterState,
      searchText,
      listItemsCount,
      pagerIndex,
      sortKey,
      sortIcon,
    },
  ]

  const fetchReport = async () => {
    const requestBody = makeRequestBody(
      calenderState,
      goalId,
      scopeType,
      deviceType,
      customFilterState,
      searchText,
      listItemsCount,
      pagerIndex,
      sortKey,
      sortIcon,
    )
    return await request<CustomDimensionValueReportResponse>(
      'POST',
      `/api/custom_dimension_report/${customDimensionId}`,
      true,
      requestBody,
    )
  }

  const queryResult = useQuery({
    queryKey,
    queryFn: fetchReport,
    select: (response) => {
      if (errorMessage) setErrorMessage('')
      return transformReport(response, listItemsCount, pagerIndex)
    },
    onError: (error: ErrorType) => {
      if (typeof error === 'string') setErrorMessage(error)
    },
    enabled: !!goal && enabled && !isCachedIdUnmatched,
  })

  return {
    ...queryResult,
    errorMessage,
  }
}

const makeRequestBody = (
  calenderState: CalenderState,
  goalId: number,
  scopeType: ScopeType,
  deviceType: DeviceType,
  customFilterState: CustomFilterState[],
  searchText: string,
  listItemsCount: number,
  pagerIndex: number,
  sortKey: SortKey,
  sortIcon: SortIconState,
) => {
  const body = {
    search_from: getDateStringYMD(calenderState.startDate, '-'),
    search_to: getDateStringYMD(calenderState.endDate, '-'),
    goal_id: goalId,
    scope_type: getScopeTypeApiValue(scopeType),
    page_layout: getDeviceLayoutNumber(deviceType),
    search_text: searchText,
    filters: makeReportFilters(customFilterState),
    limit: listItemsCount,
    offset: listItemsCount * pagerIndex || 0,
    sort_key: sortKey,
    sort_order: convertSortOrder(sortIcon),
  }

  return JSON.stringify(body)
}

const transformReport = (
  response: CustomDimensionValueReportResponse,
  listItemsCount: number,
  pagerIndex: number,
): CustomDimensionValueReport => {
  const sessionCounts = response.results.map((item) => item.session_count)
  const userCounts = response.results.map((item) => item.user_count)
  const goalCounts = response.results.map((item) => item.goal_count)

  const minSessionCount = Math.min(...sessionCounts)
  const maxSessionCount = Math.max(...sessionCounts)
  const minUserCount = Math.min(...userCounts)
  const maxUserCount = Math.max(...userCounts)
  const minGoalCount = Math.min(...goalCounts)
  const maxGoalCount = Math.max(...goalCounts)

  const results = response.results.map((item, index) => {
    return {
      index: listItemsCount * pagerIndex + (index + 1),
      customDimensionValue: item.custom_dimension_value,
      sessionCount: item.session_count,
      sessionScaledPercent: getScaledPercent(item.session_count, minSessionCount, maxSessionCount),
      sessionOverallRatio: Percent.parse(item.session_count / response.all_session_count),
      userCount: item.user_count,
      userScaledPercent: getScaledPercent(item.user_count, minUserCount, maxUserCount),
      userOverallRatio: Percent.parse(item.user_count / response.all_user_count),
      goalCount: item.goal_count,
      goalCountScaledPercent: getScaledPercent(item.goal_count, minGoalCount, maxGoalCount),
      goalCountOverallRatio: Percent.parse(item.goal_count / response.all_goal_count),
      goalRate: Percent.parse(item.goal_rate),
    }
  })

  return {
    name: response.custom_dimension.name,
    customDimensionId: response.custom_dimension.id,
    count: response.count,
    results,
    allSessionCount: response.all_session_count,
    allUserCount: response.all_user_count,
    allGoalCount: response.all_goal_count,
    allGoalRate: Percent.parse(response.all_goal_rate),
  }
}
