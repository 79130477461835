import * as React from 'react'
import { DataTable, DataTh, DataTd, SortControl, DataTr, CELL_BACKGROUND_COLORS } from '../../../components/DataTable'
import { DataContentLink } from '../../../components/DataTable/DataContentLink'
import { SCOPE_TYPE, useScopeType } from '../../../util/hooks/useScopeType'
import { CustomDimensionReportItem } from '../../../util/hooks/api/CustomDimensionReport/useCustomDimensionReport'
import { HELP_TEXTS } from '../../../constants'
import { ProjectContext } from '../../ProjectRoot'
import { SORT_KEYS } from '../../../util/hooks/api/CustomDimensionReport/constants'
import { CustomDimensionReportContext } from '../../../contexts/CustomDimentionReportContext'

interface Props {
  readonly items?: CustomDimensionReportItem[]
}

export const ReportTable = ({ items }: Props) => {
  if (!items) return null
  const {
    state: { baseUrl },
  } = React.useContext(ProjectContext)
  const { actions } = React.useContext(CustomDimensionReportContext)

  const { scopeType, scopeTypeLabel } = useScopeType()

  const isScopeTypeUser = scopeType === SCOPE_TYPE.USER
  const viewCountHelpText = isScopeTypeUser ? HELP_TEXTS.USER_COUNT : HELP_TEXTS.SESSION_COUNT
  const viewCountSortKey = isScopeTypeUser ? SORT_KEYS.USER_COUNT : SORT_KEYS.SESSION_COUNT

  const { search } = window.location

  return (
    <DataTable>
      <thead>
        <DataTr>
          <DataTh noBorderRight minWidth="36px"></DataTh>
          <DataTh fillWidth textAlign="left">
            カスタムディメンション
          </DataTh>
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="126px">
            <SortControl
              helpText={viewCountHelpText}
              helpWidth={200}
              sortKey={viewCountSortKey}
              sortIcon={actions.getSortIcon(viewCountSortKey)}
              onSortClick={actions.setSortValues}
            >
              {scopeTypeLabel}数
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="102px">
            <SortControl
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_GOAL_COUNT : HELP_TEXTS.SESSION_GOAL_COUNT}
              helpWidth={200}
              sortKey={SORT_KEYS.GOAL_COUNT}
              sortIcon={actions.getSortIcon(SORT_KEYS.GOAL_COUNT)}
              onSortClick={actions.setSortValues}
            >
              ゴール数
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="102px">
            <SortControl
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_GOAL_RATE : HELP_TEXTS.SESSION_GOAL_RATE}
              helpLeft={-150}
              sortKey={SORT_KEYS.GOAL_RATE}
              sortIcon={actions.getSortIcon(SORT_KEYS.GOAL_RATE)}
              onSortClick={actions.setSortValues}
            >
              ゴール率
            </SortControl>
          </DataTh>
        </DataTr>
      </thead>

      <tbody>
        {items.map((item, index) => (
          <DataTr key={item.customDimensionId}>
            <DataTd noBorderRight>{index + 1}</DataTd>
            <DataTd textAlign="left" ellipsis tooltipContent={item.name}>
              <DataContentLink to={`${baseUrl}report/custom-dimension/${item.customDimensionId}${search}`}>
                {item.name}
              </DataContentLink>
            </DataTd>
            <DataTd>{(isScopeTypeUser ? item.userCount : item.sessionCount).toLocaleString()}</DataTd>
            <DataTd>{item.goalCount.toLocaleString()}</DataTd>
            <DataTd>{item.goalRate.toFixed(2)}%</DataTd>
          </DataTr>
        ))}
      </tbody>
    </DataTable>
  )
}
