import * as React from 'react'
import { QuestionCircle } from '@styled-icons/bootstrap/QuestionCircle'

import { colors } from '../../styleConstants'
import styled from 'styled-components'

interface HelpLinkProps {
  title: string
  link: string
  style?: React.CSSProperties
}

export const HelpLink = ({ title, link, style }: HelpLinkProps) => {
  return (
    <Link href={link} target="_blank" rel="noopener" style={style}>
      <QuestionCircle size={14} />
      <Text>{title}</Text>
    </Link>
  )
}

const Link = styled.a`
  display: flex;
  align-items: center;
  color: ${colors.contentBlue.blue7};
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  margin-left: 5px;

  &:visited {
    color: ${colors.purple};
  }

  &:hover {
    color: ${colors.contentBlue.blue6};
  }
`

const Text = styled.div`
  margin-left: 5px;
`
