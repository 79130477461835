import { DIMENSION_IDS } from '../api/TrafficReport/constants'
import { DimensionId } from '../api/TrafficReport/types'
import { TRAFFIC_REPORT_DIMENSION_CHOICES_COOKIE_KEY } from './constants'
import { useCookieState } from './useCookieState'

const SEARCH_PARAM = 'traffic_dimensions'

const SHORT_DIMENSION_IDS = {
  CHANNEL: 'channel',
  SOURCE: 'source',
  MEDIUM: 'medium',
  CAMPAIGN: 'campaign',
} as const

export type ShortDimensionId = typeof SHORT_DIMENSION_IDS[keyof typeof SHORT_DIMENSION_IDS]

const FULL_TO_SHORT_DIMENSION_IDS: Record<DimensionId, ShortDimensionId> = {
  [DIMENSION_IDS.CHANNEL]: SHORT_DIMENSION_IDS.CHANNEL,
  [DIMENSION_IDS.SOURCE]: SHORT_DIMENSION_IDS.SOURCE,
  [DIMENSION_IDS.MEDIUM]: SHORT_DIMENSION_IDS.MEDIUM,
  [DIMENSION_IDS.CAMPAIGN]: SHORT_DIMENSION_IDS.CAMPAIGN,
} as const

const SHORT_TO_FULL_DIMENSION_IDS: Record<ShortDimensionId, DimensionId> = {
  [SHORT_DIMENSION_IDS.CHANNEL]: DIMENSION_IDS.CHANNEL,
  [SHORT_DIMENSION_IDS.SOURCE]: DIMENSION_IDS.SOURCE,
  [SHORT_DIMENSION_IDS.MEDIUM]: DIMENSION_IDS.MEDIUM,
  [SHORT_DIMENSION_IDS.CAMPAIGN]: DIMENSION_IDS.CAMPAIGN,
}

/**
 * 流入レポートの指標選択状態を管理するカスタムフック
 *
 * @returns {object} - 選択された指標の配列とその更新関数を返す
 * @returns {DimensionId[]} dimensionChoices - 選択されたディメンションの配列
 * @returns {Function} setDimensionChoices - ディメンションの選択状態を更新する関数
 */
export const useTrafficReportDimensionChoices = () => {
  const [dimensionChoices, setDimensionChoices] = useCookieState<DimensionId[]>({
    cookieKey: TRAFFIC_REPORT_DIMENSION_CHOICES_COOKIE_KEY,
    initialValue: [DIMENSION_IDS.CHANNEL],
  })

  return { dimensionChoices, setDimensionChoices }
}

export const getSearchParamsDimensionChoices = (): DimensionId[] | null => {
  const searchParams = new URLSearchParams(location.search)
  const paramValue = searchParams.get(SEARCH_PARAM)
  if (!paramValue) return null

  const shortIds = paramValue.split('-')
  const dimensionIds = shortIds.map((id) => SHORT_TO_FULL_DIMENSION_IDS[id as ShortDimensionId]).filter(Boolean)

  return dimensionIds.length > 0 ? dimensionIds : null
}

export const setSearchParamsDimensionChoices = (dimensions: DimensionId[]) => {
  const shortIds = dimensions.map((id) => FULL_TO_SHORT_DIMENSION_IDS[id])
  const currentUrl = new URL(window.location.href)
  currentUrl.searchParams.set(SEARCH_PARAM, shortIds.join('-'))
  history.replaceState(null, '', currentUrl.toString())
}

export const removeSearchParamsDimensionChoices = () => {
  const currentUrl = new URL(window.location.href)
  currentUrl.searchParams.delete(SEARCH_PARAM)
  history.replaceState(null, '', currentUrl.toString())
}
