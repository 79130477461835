import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors, layout } from '../../styleConstants'
import { GlobalContext } from '../../GlobalState'
import { HELP_LINKS } from '../../constants'

interface Props {
  readonly hidden?: boolean
}

export const Footer = React.memo(({ hidden = false }: Props) => {
  const context = React.useContext(GlobalContext)
  const teamCode = context?.state?.AccountInfo?.teamCode
  const href = `https://www.uncovertruth.co.jp/contact-content-analytics/${
    teamCode ? `?ca_support_form_organizer_code=${teamCode}` : ''
  }`

  return (
    <Container className={cc([{ hidden }])}>
      <DesignedLink href="/terms" target="_blank" rel="noopener">
        サービス利用約款
      </DesignedLink>
      <DesignedLink href={HELP_LINKS.TOP} target="_blank" rel="noopener">
        ヘルプ
      </DesignedLink>
      <DesignedLink href={href} target="_blank" rel="noopener">
        お問い合わせ
      </DesignedLink>
    </Container>
  )
})

const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  min-width: ${layout.settingPageWidth};
  height: ${layout.footerHeight};
  color: ${colors.gray500};
  background-color: ${colors.white};
  border-top: 1px solid ${colors.gray300};
  padding: 0.5rem 2rem;

  &.hidden {
    display: none;
  }
`

const DesignedLink = styled.a`
  color: ${colors.link.base};
  font-size: 0.8rem;
  text-decoration: none;
  margin-right: 1.5rem;
  cursor: pointer;

  &:visited {
    color: ${colors.link.visited};
  }

  &:hover {
    color: ${colors.link.hover};
  }
`
