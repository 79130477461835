import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import { CalenderState } from '../../../../components/common/DayPickerRange'
import { ScopeType, useScopeType } from '../../useScopeType'
import { CustomFilterState } from '../Filter/types'
import { getDateStringYMD } from '../../../Date'
import { getScopeTypeApiValue } from '../../../getScopeTypeApiValue'
import { makeReportFilters } from '../../../makeReportFilters'
import { ErrorType, request } from '../../../request'
import { CUSTOM_DIMENSION_REPORT_QUERY_KEY } from '../constants'
import { DeviceType, getDeviceLayoutNumber, useDeviceType } from '../../useDeviceType'
import { useGoalList } from '../Goal/useGoalList'
import { Percent } from '../../../Parse'
import { useGoalId } from '../../useGoalId'
import { ReportContext } from '../../../../contexts/ReportProvider'
import { CustomFilterContext } from '../../../../contexts/CustomFilterContext'
import { CustomDimensionReportContext } from '../../../../contexts/CustomDimentionReportContext'
import { SortIconState } from '../../../../components/common/SortButton'
import { SortKey } from './types'
import { convertSortOrder } from './utils'

export interface CustomDimensionReportItemResponse {
  readonly custom_dimension_id: number
  readonly name: string
  readonly session_count: number
  readonly user_count: number
  readonly goal_count: number
  readonly goal_rate: number
}

export interface CustomDimensionReportResponse {
  readonly results: Array<CustomDimensionReportItemResponse>
}

export interface CustomDimensionReportItem {
  readonly customDimensionId: number
  readonly name: string
  readonly sessionCount: number
  readonly userCount: number
  readonly goalCount: number
  readonly goalRate: number
}

interface Props {
  readonly projectId: number
  readonly enabled?: boolean
}

export const useCustomDimensionReport = ({ projectId, enabled = true }: Props) => {
  const {
    state: { calenderState, uuid },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)
  const {
    state: { searchText, sortKey, sortIcon },
  } = React.useContext(CustomDimensionReportContext)

  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { goalId } = useGoalId({ projectId })

  const { getGoal } = useGoalList({ projectId })
  const goal = getGoal(goalId)

  const [errorMessage, setErrorMessage] = React.useState('')

  const queryKey = [
    CUSTOM_DIMENSION_REPORT_QUERY_KEY,
    {
      uuid,
      projectId,
      calenderState,
      goal,
      scopeType,
      deviceType,
      customFilterState,
      searchText,
      sortKey,
      sortIcon,
    },
  ]

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const requestBody = makeRequestBody(
        calenderState,
        goalId,
        scopeType,
        deviceType,
        customFilterState,
        searchText,
        sortKey,
        sortIcon,
      )
      return await request<CustomDimensionReportResponse>(
        'POST',
        `/api/projects/${projectId}/custom_dimension_report/`,
        true,
        requestBody,
      )
    },
    select: (response): CustomDimensionReportItem[] => {
      if (errorMessage) setErrorMessage('')
      if (response.results.length === 0) return []

      return response.results.map((item) => ({
        customDimensionId: item.custom_dimension_id,
        name: item.name,
        sessionCount: item.session_count,
        userCount: item.user_count,
        goalCount: item.goal_count,
        goalRate: Percent.parse(item.goal_rate),
      }))
    },
    onError: (error: ErrorType) => {
      if (typeof error === 'string') setErrorMessage(error)
    },
    enabled: !!goal && enabled,
  })

  return {
    ...queryResult,
    errorMessage,
  }
}

const makeRequestBody = (
  calenderState: CalenderState,
  goalId: number,
  scopeType: ScopeType,
  deviceType: DeviceType,
  customFilterState: CustomFilterState[],
  searchText: string,
  sortKey: SortKey,
  sortIcon: SortIconState,
) => {
  const body = {
    search_from: getDateStringYMD(calenderState.startDate, '-'),
    search_to: getDateStringYMD(calenderState.endDate, '-'),
    goal_id: goalId,
    scope_type: getScopeTypeApiValue(scopeType),
    page_layout: getDeviceLayoutNumber(deviceType),
    search_text: searchText,
    filters: makeReportFilters(customFilterState),
    sort_key: sortKey,
    sort_order: convertSortOrder(sortIcon),
  }

  return JSON.stringify(body)
}
