import * as React from 'react'
import {
  DataTable,
  DataTh,
  DataTd,
  SortControl,
  SubText,
  DataDataBar,
  DataTr,
  DataSummaryTr,
  CELL_BACKGROUND_COLORS,
} from '../../../components/DataTable'
import { DataContentLink } from '../../../components/DataTable/DataContentLink'
import { SCOPE_TYPE, useScopeType } from '../../../util/hooks/useScopeType'
import { CustomDimensionValueReport } from '../../../util/hooks/api/CustomDimensionReport/useCustomDimensionValueReport'
import { DATA_BAR_COLORS } from '../../../styleConstants'
import { HELP_TEXTS } from '../../../constants'
import { SORT_KEYS } from '../../../util/hooks/api/CustomDimensionReport/constants'
import { CustomDimensionReportContext } from '../../../contexts/CustomDimentionReportContext'
import {
  ADDON_FILTERS_PARAM_NAME,
  AGGREGATE_CONDITIONS,
  AGGREGATE_SCOPES,
  DIMENSION_KEYS,
  DIMENSION_MATCH_CONDITIONS,
} from '../../../util/hooks/api/Filter/constants'
import { CustomDimensionUrlParams, CustomFilterUrlParams } from '../../../util/hooks/api/Filter/types'
import { ProjectContext } from '../../ProjectRoot'

interface Props {
  reportData?: CustomDimensionValueReport
}

export const ReportTable = ({ reportData }: Props) => {
  if (!reportData) return null

  const { allSessionCount, allUserCount, allGoalCount, allGoalRate, customDimensionId } = reportData

  const {
    state: { baseUrl },
  } = React.useContext(ProjectContext)

  const { actions } = React.useContext(CustomDimensionReportContext)

  const { scopeType, scopeTypeLabel } = useScopeType()

  const isScopeTypeUser = scopeType === SCOPE_TYPE.USER
  const viewCountHelpText = isScopeTypeUser ? HELP_TEXTS.USER_COUNT : HELP_TEXTS.SESSION_COUNT
  const viewCountSortKey = isScopeTypeUser ? SORT_KEYS.USER_COUNT : SORT_KEYS.SESSION_COUNT

  const { search } = window.location

  /**
   * ページレポートへのURLを作成する
   *
   * @param {string} value - カスタムディメンションの値
   *
   * @return {string} - パラメータ付きのページレポートURL
   */
  function makePageReportUrl(value: string): string {
    const dimension: CustomDimensionUrlParams = [
      DIMENSION_KEYS.CUSTOM_DIMENSION,
      DIMENSION_MATCH_CONDITIONS.FULL,
      value,
      customDimensionId,
    ]
    const json: CustomFilterUrlParams[] = [[AGGREGATE_SCOPES.SESSION, AGGREGATE_CONDITIONS.INCLUDE, [dimension]]]
    const searchValue = JSON.stringify(json)
    const addonSearchParam = `${ADDON_FILTERS_PARAM_NAME}=${searchValue}`
    const allSearchParams = search ? `${search}&${addonSearchParam}` : `?${addonSearchParam}`
    return `${baseUrl}report/page${allSearchParams}`
  }

  return (
    <DataTable>
      <thead>
        <DataTr>
          <DataTh noBorderRight minWidth="36px"></DataTh>
          <DataTh fillWidth textAlign="left">
            カスタムディメンション
          </DataTh>
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="126px">
            <SortControl
              subTitle={'(対全体比率)'}
              helpText={viewCountHelpText}
              helpWidth={200}
              sortKey={viewCountSortKey}
              sortIcon={actions.getSortIcon(viewCountSortKey)}
              onSortClick={actions.setSortValues}
            >
              {scopeTypeLabel}数
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="119px">
            <SortControl
              subTitle={'(対全体比率)'}
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_GOAL_COUNT : HELP_TEXTS.SESSION_GOAL_COUNT}
              helpWidth={200}
              sortKey={SORT_KEYS.GOAL_COUNT}
              sortIcon={actions.getSortIcon(SORT_KEYS.GOAL_COUNT)}
              onSortClick={actions.setSortValues}
            >
              ゴール数
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="119px">
            <SortControl
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_GOAL_RATE : HELP_TEXTS.SESSION_GOAL_RATE}
              helpLeft={-150}
              sortKey={SORT_KEYS.GOAL_RATE}
              sortIcon={actions.getSortIcon(SORT_KEYS.GOAL_RATE)}
              onSortClick={actions.setSortValues}
            >
              ゴール率
            </SortControl>
          </DataTh>
        </DataTr>
      </thead>

      <tbody>
        <DataSummaryTr>
          <DataTd noBorderRight></DataTd>
          <DataTd textAlign="left">全体</DataTd>
          <DataTd>
            <div>{(isScopeTypeUser ? allUserCount : allSessionCount).toLocaleString()}</div>
            <SubText>(100.0%)</SubText>
          </DataTd>
          <DataTd>
            <div>{allGoalCount.toLocaleString()}</div>
            <SubText>(100.0%)</SubText>
          </DataTd>
          <DataTd>{allGoalRate.toFixed(2)}%</DataTd>
        </DataSummaryTr>

        {reportData.results.map((item) => (
          <DataTr key={item.index}>
            <DataTd noBorderRight>{item.index}</DataTd>
            <DataTd textAlign="left" ellipsis tooltipContent={item.customDimensionValue}>
              <DataContentLink to={makePageReportUrl(item.customDimensionValue)}>
                {item.customDimensionValue}
              </DataContentLink>
            </DataTd>
            <DataTd noPadding>
              <DataDataBar
                barPercent={isScopeTypeUser ? item.userScaledPercent : item.sessionScaledPercent}
                barColor={DATA_BAR_COLORS.COUNT}
              >
                <div>{(isScopeTypeUser ? item.userCount : item.sessionCount).toLocaleString()}</div>
                <SubText>
                  ({isScopeTypeUser ? item.userOverallRatio.toFixed(1) : item.sessionOverallRatio.toFixed(1)}%)
                </SubText>
              </DataDataBar>
            </DataTd>
            <DataTd noPadding>
              <DataDataBar barPercent={item.goalCountScaledPercent} barColor={DATA_BAR_COLORS.COUNT}>
                <div>{item.goalCount.toLocaleString()}</div>
                <SubText>({item.goalCountOverallRatio.toFixed(1)}%)</SubText>
              </DataDataBar>
            </DataTd>
            <DataTd>{item.goalRate.toFixed(2)}%</DataTd>
          </DataTr>
        ))}
      </tbody>
    </DataTable>
  )
}
