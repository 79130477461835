import { colors } from '../../styleConstants'
import { CustomFilterState } from '../../util/hooks/api/Filter/types'

const DIMENSION_BACKGROUND_COLORS = {
  FIRST_ROW: colors.lightCyan,
  SECOND_ROW: colors.yellow2,
  THIRD_ROW: colors.lightGreen,
  FOURTH_ROW: colors.lightPurple,
  NOT_SELECTED: colors.white,
} as const

export type DimensionBackgroundColor = typeof DIMENSION_BACKGROUND_COLORS[keyof typeof DIMENSION_BACKGROUND_COLORS]

const DIMENSION_BORDER_COLORS = {
  FIRST_ROW: colors.contentBlue.blue5,
  SECOND_ROW: colors.darkYellow,
  THIRD_ROW: colors.green,
  FOURTH_ROW: colors.purple2,
  NOT_SELECTED: colors.gray250,
} as const

const DIMENSION_ORDER_TO_BACKGROUND = {
  0: DIMENSION_BACKGROUND_COLORS.FIRST_ROW,
  1: DIMENSION_BACKGROUND_COLORS.SECOND_ROW,
  2: DIMENSION_BACKGROUND_COLORS.THIRD_ROW,
  3: DIMENSION_BACKGROUND_COLORS.FOURTH_ROW,
} as const

const DIMENSION_ORDER_TO_BORDER = {
  0: DIMENSION_BORDER_COLORS.FIRST_ROW,
  1: DIMENSION_BORDER_COLORS.SECOND_ROW,
  2: DIMENSION_BORDER_COLORS.THIRD_ROW,
  3: DIMENSION_BORDER_COLORS.FOURTH_ROW,
} as const

/**
 * ディメンション選択の順序に応じた背景色を返す
 * @param order - ディメンション選択の順序（0-based index）
 * @returns 背景色
 */
export const getDimensionBackgroundColorByOrder = (order: number): DimensionBackgroundColor => {
  return (
    DIMENSION_ORDER_TO_BACKGROUND[order as keyof typeof DIMENSION_ORDER_TO_BACKGROUND] ??
    DIMENSION_BACKGROUND_COLORS.NOT_SELECTED
  )
}

/**
 * ディメンション選択の順序に応じたボーダー色と番号アイコンの背景色を返す
 * @param order - ディメンション選択の順序（0-based index）
 * @returns ボーダー色、番号アイコンの背景色
 */
export const getDimensionBorderByOrder = (order: number): string => {
  return (
    DIMENSION_ORDER_TO_BORDER[order as keyof typeof DIMENSION_ORDER_TO_BORDER] ?? DIMENSION_BORDER_COLORS.NOT_SELECTED
  )
}

export const makeItemsNotExistsMessage = (searchText: string, customFilterState: CustomFilterState[]): string => {
  if (searchText.length > 0 || customFilterState.length > 0) {
    return 'ご指定の条件に一致するデータはありません。条件を変えて再度検索してください。'
  }
  return '検索対象期間にはデータがありません。'
}
