import * as React from 'react'
import styled from 'styled-components'
import { colors, style } from '../../styleConstants'
import ReactPaginate from 'react-paginate'
import { ChevronLeft } from '@styled-icons/boxicons-solid/ChevronLeft'
import { ChevronRight } from '@styled-icons/boxicons-solid/ChevronRight'

interface Props {
  readonly initialPage?: number
  readonly disableInitialCallback?: boolean
  readonly pageCount: number
  readonly marginPagesDisplayed: number
  readonly pageRangeDisplayed: number
  readonly forcePage?: number
  readonly onPageChange?: (selectedItem: { selected: number }) => void
}

export function Paginate(props: Props) {
  const {
    initialPage,
    disableInitialCallback,
    pageCount,
    marginPagesDisplayed,
    pageRangeDisplayed,
    forcePage,
    onPageChange,
  } = props
  return (
    <Container>
      <ReactPaginate
        initialPage={initialPage}
        disableInitialCallback={disableInitialCallback} // 初期設定時にonPageChangeを呼ばない
        pageCount={pageCount}
        forcePage={forcePage}
        marginPagesDisplayed={marginPagesDisplayed}
        pageRangeDisplayed={pageRangeDisplayed}
        previousLabel={<ChevronLeft size={15} />}
        nextLabel={<ChevronRight size={15} />}
        breakLabel={'...'}
        containerClassName={'pagination'}
        activeClassName={'active'}
        onPageChange={onPageChange}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: 1rem auto;
  line-height: 1.2;

  .pagination {
    padding: 0;
    margin: 0;
    box-shadow: 0px 2px 4px ${style.shadow};
  }

  .pagination > ul {
    display: inline-block;
    padding: 0;
  }

  .pagination > li {
    display: inline-block;
    border: 1px solid ${colors.gray300};
    background-color: ${colors.white};
    color: ${colors.black};
    cursor: pointer;
    user-select: none;
    height: 31px;
    min-width: 31px;
    text-align: center;
  }

  .pagination > li a {
    padding: 7px 8px;
    display: inline-block;
    color: ${colors.black};
    outline: none;
  }

  .pagination > li.active {
    background: ${colors.gray200};
    outline: none;
    font-weight: bold;
  }

  .pagination > li:not(:last-child) {
    border-right: none;
  }

  .pagination > li.previous a,
  .pagination > li.next a {
  }
`
