import React from 'react'
import { Link } from '../../common/Link'
import { HELP_LINKS } from '../../../constants'

export const TrackingCodePvNotice = () => {
  return (
    <>
      トラッキングコードを設置したページはPV消費対象となります。
      <br />
      PV消費を節約するために特定のページにのみトラッキングコードを発火させたい場合は、タグマネージャー（GTMなど）にてトリガーを制限してください。
      <br />
      制限方法は
      <Link href={HELP_LINKS.TRACKING_CODE_CONTROL_GTM_HOW_TO} target="_blank" rel="noopener">
        こちら
      </Link>
      をご覧ください。
    </>
  )
}
