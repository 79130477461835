import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { colors, layout } from '../styleConstants'
import { SimplePageLayout } from '../components/layout/SimplePageLayout'

export function Terms(_: RouteComponentProps) {
  return (
    <SimplePageLayout width={'calc(1000px + 6rem)'}>
      <Container>
        <h1 style={{ margin: '1.5rem auto 2.5rem auto' }}>コンテンツアナリティクスサービス利用約款</h1>
        <Text>{`コンテンツアナリティクスサービス利用約款（以下、「本約款」）は、株式会社UNCOVER TRUTH（以下、「提供者」）が著作権等の知的財産権を保有し、ウェブサイトのアクセス解析ならびに配信ツール連携等のマーケティングを行うためのソフトウェア・プログラムである「CONTENT ANALYTICS」の利用（以下、「本サービス」）を目的とする契約（以下、「利用契約」）について定めます。`}</Text>

        <Title>第1条（本サービスの利用）</Title>
        <Text>{`　1. 本サービスは、契約者が自己の所有または管理、運営するウェブサイト（以下、「契約者サービス」）に適用して利用するものとします。契約者は、本約款に同意いただくことによって、本サービスをご利用いただくことができます。`}</Text>
        <Text>{`　2. 本サービスを利用した契約者は、本約款および提供者のプライバシーポリシー（https://www.uncovertruth.co.jp/privacy-policy/）に同意したものとみなします。`}</Text>

        <Title>第2条（利用契約の成立）</Title>
        <Text>{`　1. 本サービスの利用希望者は、本約款の内容を承諾の上、提供者所定の申込方法（利用申込書の提出や利用申込フォームからの申込等を含むがこれに限らない）で、提供者または提供者に本サービスの販売を許諾された販売店（以下、「販売店」）に申し出ることで、本サービス利用のための申込みを行うものとします。`}</Text>
        <Text>{`　2. 前項の申込みを行い、これが提供者により承諾された時点で、利用契約が成立・締結されたものとします。`}</Text>
        <Text>{`　3. 利用契約が成立した本サービスの利用申込者（以下、「契約者」）による本サービスの利用は、前項に定める利用契約締結後、別途提供者が指定するサービス開始日をもって開始されるものとします。`}</Text>

        <Title>第3条（利用期間）</Title>
        <Text>{`　1. 本サービスの最低利用期間は、第2条（利用契約の成立）第3項で指定するサービス開始日より1年間とします。`}</Text>
        <Text>{`　2. 本サービスの利用は、契約者が第26条（契約者による利用契約の解約）に従い本サービス利用の終了を申し出ない限り、利用申込に記載の利用期間の満了日から更に1年間更新されるものとし、その後の更新についても同様とします。`}</Text>

        <Title>第4条（利用申込みの審査）</Title>
        <Text>{`　1. 契約者は、提供者の指定する方法にて、本サービスの利用申込を行うものとします。`}</Text>
        <Text>{`　2. 提供者は、本サービスの利用申込みに対して審査を行い、本サービス利用希望者が次の各号のいずれかに該当する場合には、利用契約を承諾しない場合があります。`}</Text>
        <Text>{`　　(1) 利用申込に虚偽の事実を記載していることが明らかになった場合`}</Text>
        <Text>{`　　(2) 提供者が競合他社等、事業上の秘密を調査する目的で利用申込みをしていることが明らかになった場合`}</Text>
        <Text>{`　　(3) 過去に本約款違反等により、利用契約を解除されている場合`}</Text>
        <Text>{`　　(4) 本約款に違反して本サービスを利用するおそれがある場合`}</Text>
        <Text>{`　　(5) 個人の場合`}</Text>
        <Text>{`　　(6) 第27条（反社会的勢力の排除）第1項各号に該当し、または同条第2項各号に掲げる行為を現在または過去に行っていた者である場合`}</Text>
        <Text>{`　　(7) その他提供者が適当でないと判断した場合`}</Text>

        <Title>第5条（再委託）</Title>
        <Text>{`提供者は、本サービスの提供に必要な業務の一部を、提供者の責任において第三者に委託できるものとします。この場合において、提供者は、委託業務の実施に係る委託先の行為に関して、一切の責任を負うものとします。`}</Text>

        <Title>第6条（外部サービスの利用）</Title>
        <Text>{`提供者は、提供者が指定する外部事業者のサービス（以下、「外部サービス」）を本サービスと連携する場合があります。この場合において、契約者が外部サービスを利用するときは、契約者はその利用に関して一切の責任を負うものとし、外部サービスの利用に起因する損害について提供者に対して何らの法的責任も追及しないものとします。`}</Text>

        <Title>第7条（権利譲渡の禁止）</Title>
        <Text>{`契約者は、提供者の書面による事前の承諾なしに、本サービスを利用する権利を第三者に許諾、再許諾、譲渡または承継させないものとします。`}</Text>

        <Title>第8条（契約者の氏名等の変更）</Title>
        <Text>{`　1. 契約者は、その氏名、名称、住所に変更があったときは、速やかに書面によりその旨を提供者に通知するものとします。`}</Text>
        <Text>{`　2. 契約者が前項の通知を行ったことにより生じた契約者の損失その他の負担について、提供者は一切責任を負いません。`}</Text>

        <Title>第9条（権利帰属および利用責任）</Title>
        <Text>{`　1. 契約者は、本サービスに関するドキュメントやプログラムの著作権その他一切の権利が提供者に帰属し、契約者は、本約款の条件に従ってのみ使用を許諾されていることを承認します。`}</Text>
        <Text>{`　2. 契約者は、本サービスを契約者の責任において使用するものとし、本サービスに基づいて提供された情報に基づく契約者の決定または行為に対し、提供者は、一切の責任を負わないものとします。`}</Text>

        <Title>第10条（禁止事項）</Title>
        <Text>{`提供者は、本サービスに関して、契約者が次の各号に掲げる行為を行うことを禁止するものとします。なお、当該行為またはその恐れのある行為が確認された場合、提供者は、契約者による本サービスの利用を禁止する場合があります。`}</Text>
        <Text>{`　(1) 国内外の諸法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為`}</Text>
        <Text>{`　(2) 公序良俗に反する目的のために本サービスを利用する行為`}</Text>
        <Text>{`　(3) 提供者または第三者の著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上または契約上の権利を侵害する行為`}</Text>
        <Text>{`　(4) 以下の内容を含むコンテンツの配信等を行う行為`}</Text>
        <Text>{`　　① 国内外の法令等に違反する内容`}</Text>
        <Text>{`　　② 公序良俗に違反する内容`}</Text>
        <Text>{`　　③ 第三者の法令上または契約上の権利を侵害する内容`}</Text>
        <Text>{`　　④ 誇大広告、不当表示、虚偽情報または第三者に誤認を生じさせる内容`}</Text>
        <Text>{`　　⑤ わいせつ、暴力的・残虐的、または未成年者に有害な内容`}</Text>
        <Text>{`　　⑥ 第三者に嫌悪感や不快感を与える内容`}</Text>
        <Text>{`　　⑦ その他、提供者が不適当と判断した内容`}</Text>
        <Text>{`　(5) 本サービスまたは本サービスに関するドキュメントやプログラムを修正、翻訳、リバースエンジニアリング、もしくは逆コンパイル、逆アセンブルまたは本サービスの派生サービスもしくは同等のサービスを作成もしくは提供する行為`}</Text>
        <Text>{`　(6) 本サービスまたは本サービスに関するドキュメントやプログラムに高負荷をかけること、またはその恐れのある行為`}</Text>
        <Text>{`　(7) プログラム等による自動的な検索機能等を用いて本サービスを利用する行為`}</Text>
        <Text>{`　(8) 提供者の書面による事前の承諾なしに、本サービスの設定を変更する行為`}</Text>
        <Text>{`　(9) 不正アクセス行為、第三者のログインIDの利用行為、複数のログインIDの付与を受ける行為、その他これらに類する行為`}</Text>
        <Text>{`　(10) 提供者による本サービスの運営または第三者による本サービスの利用を妨害し、これらに支障を与える行為`}</Text>
        <Text>{`　(11) 第三者の個人情報、登録情報、利用履歴情報等を不正に収集、開示または提供する行為`}</Text>
        <Text>{`　(12) その他提供者が禁止する行為`}</Text>

        <Title>第11条(閲覧環境の整備)</Title>
        <Text>{`契約者は、本サービスの利用にあたり、自らの責任と費用負担により、本サービスを利用するために必要な通信機器、インターネット接続契約等を準備するものとします。`}</Text>

        <Title>第12条（ログインIDおよびパスワードの管理）</Title>
        <Text>{`　1. 契約者は、提供者から発行されたログインIDおよびパスワードの管理の責任を負うものとし、ログインIDおよびパスワードについて盗難、紛失その他の事由により管理を喪失した場合は、速やかに提供者に届け出るものとします。`}</Text>
        <Text>{`　2. 提供者は、ログインIDおよびパスワードを不正に利用されたことによる損害について、契約者の故意、過失の有無にかかわらず、一切責任を負わないものとします。`}</Text>
        <Text>{`　3. 契約者は、ログインIDおよびパスワードを第三者に利用させ、または貸与、譲渡等をしてはならないものとします。`}</Text>

        <Title>第13条(秘密保持)</Title>
        <Text>{`　1. 提供者および契約者は、相手方の事前の書面による承諾がない限り、知りえた相手方の営業上および技術上の一切の秘密情報を第三者に開示もしくは漏洩し、または本約款所定の目的以外のために使用しないものとします。`}</Text>
        <Text>{`　2. 次のいずれかに該当することを証明しうる情報について、提供者および契約者は、秘密保持義務を負わないものとします。`}</Text>
        <Text>{`　　(1) 開示を受けた時点で既に公知となっている情報`}</Text>
        <Text>{`　　(2) 開示を受けた後、情報受領者の責めによることなく公知となった情報`}</Text>
        <Text>{`　　(3) 開示を受ける以前に、情報受領者が既に知得していた情報`}</Text>
        <Text>{`　　(4) 開示を受けた後、情報受領者が正当な権限を有する第三者から開示を受けた情報`}</Text>
        <Text>{`　　(5) 法令または裁判所の命令により開示が必要とされる情報（ただし、当該開示を要求された当事者は、当該要求のあった旨を事前に他方当事者へ通知し、当該要求への対応につき他方当事者と協議・協力するものとします）`}</Text>
        <Text>{`　　(6) 情報受領者が開示された情報によることなく独自に開発した情報`}</Text>
        <Text>{`　3. 本条に基づく秘密保持義務は、利用契約終了後も有効に存続するものとします。`}</Text>

        <Title>第14条（料金の支払い）</Title>
        <Text>{`　1. 契約者は、提供者が別途定める条件に従い、本サービスの利用料金を支払うものとします。`}</Text>
        <Text>{`　2. 契約者は、前項に基づく料金を、提供者または販売店から発行される請求書記載の振込み期日までに指定の銀行口座へ振り込む方法により支払うものとします。`}</Text>
        <Text>{`　3. 料金の支払いに係る振込手数料および消費税相当額その他の租税公課は、契約者が負担するものとします。`}</Text>
        <Text>{`　4. 契約者が本条第2項に基づく支払いを遅延した場合には、契約者は、提供者または販売店に対して、年率14.6％の割合による遅延損害金を支払うものとします。`}</Text>
        <Text>{`　5. 理由の如何を問わず本サービスの利用契約が終了した場合、契約者は、利用契約終了日の翌日から起算して30日以内に、契約者のWebサイト等に設定されている本サービスのタグ（提供者にデータを送るためのプログラム）を外すものとします。契約者がこれを行わない場合、契約者は提供者に対し、前各項に準じて、利用契約終了日以後も本サービスの利用料金および遅延損害金を支払うものとします。`}</Text>

        <Title>第15条（サービス品質保証）</Title>
        <Text>{`　1. 本約款において「サービスの障害」とは、提供者の責めに帰する事由により、本サービスの全面停止状態が発生した場合をいいます。特定の端末機、あるいは限られた携帯電話端末機においてのみ確認されるもの、また、断続的にしか確認できないものに関する不具合は、「サービスの障害」に該当しないものとします。また、一部の関連機器の不調や故障があっても、バックアップ体制や二重化体制により、全体として本サービスの停止が認められない状態は、「サービスの障害」に該当しません。`}</Text>
        <Text>{`　2. 本サービスの正常稼働率を次の算式により算定した場合において95％以上のレベルが維持されている限りにおいて、提供者は、何らのサービスの障害の責任を負いません。`}</Text>
        <Text>{`　　正常稼働率（％） = 1 - [( ｢サービス障害」が発生した時間 - 第3項各号の事由による本サービスの停止・中断時間 ) ÷ 本サービスの全時間]`}</Text>
        <Text>{`　3. 提供者は、本サービスが利用する外部通信ネットワーク及び外部サービス（以下、「外部通信ネットワーク等」）との連続性、正確性、完全性について保証するものではなく、提供者の責めによらない次の各号の事項に起因する損失または損害について、直接または間接であることを問わず、また発生原因にかかわらず責任を負いません。`}</Text>
        <Text>{`　　(1) 本サービスと接続される外部通信ネットワーク等の通信回線、使用ソフトウェア、その他外部電気通信設備の不具合に起因する本サービスの停止・中断または遅延`}</Text>
        <Text>{`　　(2) 本サービスと接続される外部通信ネットワーク等の通信回線、使用ソフトウェア、その他外部電気通信設備の保守（定期的であると緊急時であるとを問わない）に伴う本サービスの停止・中断または遅延`}</Text>
        <Text>{`　　(3) 前各号の他、原因が特定できない外部通信ネットワーク等上のトラブル`}</Text>
        <Text>{`　4. 提供者は、提供者の責めに帰すべき事由により、契約者が本サービスを利用することができなかった場合において、第2項で示す正常稼働率を達成することができなかった場合は、その未達成時間に対応する料金を、契約者からの請求により、次の各号に基づき減額または返還します。ただし、事前に連絡を行った保守の場合は、減額または返還はなされないものとします。`}</Text>
        <Text>{`　　(1) 減額または返還する料金は、正常稼働率95％を下回った利用できなかった総時間（30分未満の端数は切り捨て、30分以上の端数は1時間に切り上げる）に、月額利用料を乗じた額とします。なお、1円未満の端数は、これを切り上げます。`}</Text>
        <Text>{`　　(2) 減額請求による減額は、料金返還事由発生日の翌月の請求書において履行されるものとします。`}</Text>

        <Title>第16条（テクニカル・サポート）</Title>
        <Text>{`　1. 提供者は、平日（祝祭日・年末年始を除く月曜日から金曜日）の午前10時から午後5時の時間内に、契約者に対して、次の各号にかかる本サービスのテクニカル・サポート（以下、「本サポート」）を電子メールなど提供者が指定する方法によって提供するものとします。`}</Text>
        <Text>{`　　(1) 本サービスの操作に関する質問への回答`}</Text>
        <Text>{`　　(2) 本サービスの利用上の不具合の原因の特定および検証に関する支援`}</Text>
        <Text>{`　　(3) 本サービスの利用上の不具合に対する解決策の提供`}</Text>
        <Text>{`　　(4) 前各号の他、本サービスの利便性向上のため、提供者が必要と認める一切の支援`}</Text>
        <Text>{`　2. 契約者は、提供者による本サポートがオンライン上のアクセス（管理者権限でのログインを含む）によって行なわれる場合があることを了承するものとします。`}</Text>

        <Title>第17条（サポートの範囲）</Title>
        <Text>{`本サービスの利用上の不具合が、次の各号に該当する場合には、提供者は、本サポートを提供しないものとします。`}</Text>
        <Text>{`　(1) 提供者が再現できない場合`}</Text>
        <Text>{`　(2) 本サービスに影響を及ぼしうるオペレーティング・システムまたは環境の変更に起因する場合`}</Text>
        <Text>{`　(3) 提供者以外による本サービスの修正または機能追加に起因する場合`}</Text>
        <Text>{`　(4) 本サービスで想定されていない使用方法に起因する場合`}</Text>
        <Text>{`　(5) 提供者により提供されていない他のソフトウェア製品と本サービスとの相互接続に起因する場合`}</Text>
        <Text>{`　(6) 推奨されていない動作環境上での本サービスの使用に起因する場合`}</Text>

        <Title>第18条（サービスの中止および制限）</Title>
        <Text>{`　1. 提供者は、次の各号に該当する場合には、本サービスの提供を中止することができるものとします。`}</Text>
        <Text>{`　　(1) 提供者が利用する電気通信設備の保守上または工事上やむを得ない場合`}</Text>
        <Text>{`　　(2) 提供者が利用する電気通信設備に障害が発生した場合`}</Text>
        <Text>{`　　(3) 電気通信事業者等が電気通信サービスの提供を中止することにより本サービスの提供が困難になった場合`}</Text>
        <Text>{`　　(4) 本サービスの修正の為に本サービスのアップデート版をリリースする場合、ならびに本サービスに関して重要な機能の向上、および重要な機能の更新の為にアップグレード版をリリースする場合`}</Text>
        <Text>{`　　(5) その他やむを得ない場合`}</Text>
        <Text>{`　2. 提供者は、前項各号の規定により本サービスの提供を中止するときは、緊急の場合を除き、契約者に事前にその旨を通知するものとします。なお、緊急を要し、事前に通知ができない場合は、メール等の連絡方法をもって事後に報告するものとします。`}</Text>
        <Text>{`　3. 提供者は、契約者の本サービスの利用が他の契約者の本サービスの利用に影響を及ぼすと判断した場合、契約者による本サービスの利用を制限することがあるものとします。`}</Text>

        <Title>第19条（サービスの変更、追加または廃止）</Title>
        <Text>{`　1. 提供者は、都合により本サービスの全部もしくは一部を変更、追加または廃止することができるものとします。`}</Text>
        <Text>{`　2. 提供者は、本サービスの廃止をするときは、契約者に対し、廃止の2ヶ月前までにその旨を通知するものとします。`}</Text>
        <Text>{`　3. 提供者は、本サービスの全部もしくは一部の変更、追加または廃止によって契約者に生じた損害につき、何ら責任を負わないものとします。`}</Text>

        <Title>第20条（契約者による情報利用）</Title>
        <Text>{`契約者は、本サービスにより得たデータ等を、契約者の事業に必要な範囲でのみ利用できるものとし、当該データ等は第13条（秘密保持）に基づき取り扱うものとします。`}</Text>

        <Title>第21条（提供者による情報利用）</Title>
        <Text>{`　1. 提供者は、本サービスの提供および本サービスの品質向上および提供者のサービスのご案内を目的として、本サービスにより得た情報を、統計目的あるいはサービス向上および提供者のサービスのご案内のために利用することができるものとします。`}</Text>
        <Text>{`　2. 提供者は、本サービスのシステムに蓄積されたデータを元に作成した統計データ・分析データ等について、提供者の資料および媒体資料、ならびに本サービスの利用状況報告などにおいて、契約者の情報が特定できない情報として第三者に開示する場合があります。`}</Text>
        <Text>{`　3. 提供者は、本サービスの提供および本サービスの品質向上の目的以外に契約者の情報を利用しません。なお、本条に基づく情報の利用・開示は、契約者の権利を侵害するものではありません。`}</Text>
        <Text>{`　4. 提供者は、契約者の担当者による本サービスの利用履歴情報（日時、利用ユーザー、操作履歴、IPアドレス等）を記録し、本サービスのサービス向上、契約者の担当者による利用履歴の確認、監査等を目的として当該情報を利用できるものとします。`}</Text>

        <Title>第22条（個人情報の取扱い）</Title>
        <Text>{`　1. 契約者は、本サービスの利用に際し、本サービスには、契約者サービスの利用者の氏名・住所・電話番号・メールアドレス・金融情報・勤務先等、単体で契約者サービスの利用者を特定できる情報をインポートしないものとします。`}</Text>
        <Text>{`　2. 契約者は、本サービスの利用に際し、契約者サービスの利用者の個人情報を含む本サービスのデータまたは外部データを第三者（提供者を含む）に提供する場合に必要となる個人情報保護法に基づく契約者サービスの利用者の同意取得等、然るべき措置を講じるものとします。`}</Text>
        <Text>{`　3. 契約者は、本条の違反に起因して契約者に生じる損害につき、提供者を免責し、かつ、提供者に生じる損害を補償するものとします。`}</Text>

        <Title>第23条（データの削除等）</Title>
        <Text>{`　1. 提供者は、契約者による本サービスの設定変更によって本サービスのシステムに蓄積されたデータについて、管理する責任を負わないものとします。`}</Text>
        <Text>{`　2. 提供者は、契約者との利用契約が終了した場合、本サービスのシステムに蓄積された契約者の本サービスに関連したデータを契約者との契約プラン記載の条件に基づき削除できるものとします。`}</Text>
        <Text>{`　3. 提供者は、利用契約の期間中においても、所定の時間経過後に、本サービスのシステムに蓄積された契約者の本サービスに関連したデータを削除できるものとします。`}</Text>
        <Text>{`　4. 前二項に基づき提供者が本サービスに関連したデータを削除した場合において、削除により契約者に損害が生じたとしても、提供者は一切の責任を負わないものとします。`}</Text>

        <Title>第24条（サービスの停止）</Title>
        <Text>{`提供者は、契約者が次の各号のいずれかに該当する場合には、何らの通知・催告を要せずに、直ちに本サービスを停止できるものとします。なお、停止期間中も本サービスの料金が発生するものとします。`}</Text>
        <Text>{`　(1) 支払期限を経過しても本サービスの料金、または遅延損害金を支払わないとき`}</Text>
        <Text>{`　(2) 第10条（禁止事項）に該当する行為を行っていた場合`}</Text>
        <Text>{`　(3) その他本約款または利用契約に違反したとき`}</Text>

        <Title>第25条（提供者による利用契約の解除）</Title>
        <Text>{`　1. 提供者は、前条（サービスの停止）の規定により本サービスを停止された契約者が速やかにその事由を解消しない場合には、利用契約を解除できるものとします。`}</Text>
        <Text>{`　2. 提供者は、契約者が前条（サービスの停止）各号のいずれかに該当する場合で、その事由が提供者の業務の遂行上支障を及ぼすと認められるときは、前項の規定にかかわらず、前条に定める提供の停止をすることなく、何らの通知・催告を要せず直ちに利用契約を解除できるものとします。`}</Text>
        <Text>{`　3. 提供者は、契約者が次の各号のいずれかに該当した場合には、何らの通知・催告を要せず直ちに利用契約を解除できるものとします。`}</Text>
        <Text>{`　　(1) 手形、小切手が不渡りとなったとき`}</Text>
        <Text>{`　　(2) 差押、仮差押、仮処分もしくは競売の申立てまたは租税滞納処分を受けたとき`}</Text>
        <Text>{`　　(3) 破産、会社更生手続、民事再生手続、その他法的整理手続の申立てを受けたとき、もしくはこれらの申立を自らした場合、または清算に入ったとき`}</Text>
        <Text>{`　　(4) 解散または事業の全部もしくは重要な一部を第三者に譲渡したとき`}</Text>
        <Text>{`　　(5) 監督官庁から営業取消し、または停止処分を受けたとき`}</Text>
        <Text>{`　　(6) 反社会的勢力との資金関係、取引関係その他関係があると疑われるとき`}</Text>
        <Text>{`　　(7) その他利用契約に基づく債務の履行が困難であると認めるに足る相当の理由があるとき`}</Text>
        <Text>{`　4. 本条に基づき利用契約が解除された場合、利用契約の解除日以前に発生した契約者の提供者に対する支払い債務その他本サービスの利用に係る債務は、一切免除されないものとします。また、契約者は、本サービスの利用に係る債務につき期限の利益を失うとともに、提供者に対して当該債務を直ちに弁済するものとします。`}</Text>

        <Title>第26条（契約者による利用契約の解約）</Title>
        <Text>{`　1. 契約者は、提供者または販売店に対して、提供者所定の書面を用い、利用期間満了日の1ヶ月前までに申し出ることにより利用契約を解約できるものとします。`}</Text>
        <Text>{`　2. 利用期間内に利用契約を解約する場合には、契約者は、提供者または販売店に対し残存期間の本サービス料金相当金額を支払うものとします。`}</Text>
        <Text>{`　3. 利用契約終了までに生じた契約者の一切の債務は、利用契約終了後においてもその債務が履行されるまで消滅しないものとします。`}</Text>
        <Text>{`　4. 契約者は、提供者が前条（提供者による利用契約の解除）第3項各号のいずれかに該当した場合には、提供者所定の書面を用い申し出ることにより、直ちに利用契約を解除できるものとします。`}</Text>

        <Title>第27条（反社会的勢力の排除）</Title>
        <Text>{`　1. 契約者および提供者は、現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下、これらを「暴力団員等」）に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ、将来にわたっても該当しないことを確約するものとします。`}</Text>
        <Text>{`　　(1) 暴力団員等が経営を支配していると認められる関係を有すること`}</Text>
        <Text>{`　　(2) 暴力団員等が経営に実質的に関与していると認められる関係を有すること`}</Text>
        <Text>{`　　(3) 自己、自社もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること`}</Text>
        <Text>{`　　(4) 暴力団員等に対して暴力団員等と知りながら資金を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること`}</Text>
        <Text>{`　　(5) 役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること`}</Text>
        <Text>{`　2. 契約者および提供者は、自らまたは第三者をして、次の各号の一にでも該当する行為を行わないことを、互いに確約するものとします。`}</Text>
        <Text>{`　　(1) 暴力的な要求行為`}</Text>
        <Text>{`　　(2) 法的な責任を超えた不当な要求行為`}</Text>
        <Text>{`　　(3) 取引に関して、脅迫的な言動をし、または暴力を用いる行為`}</Text>
        <Text>{`　　(4) 風説を流布し、偽計を用い、もしくは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為`}</Text>
        <Text>{`　　(5) その他前各号に準ずる行為`}</Text>
        <Text>{`　3. 契約者および提供者は、相手方が前各項のいずれかに違反したと合理的に判断した場合、催告および自己の債務の履行の提供をすることなく、利用契約を直ちに解除できるものとします。`}</Text>
        <Text>{`　4. 前項に基づき解除を行った当事者は、解除に起因しまたは関連して相手方に損害等が生じた場合であっても、当該損害等について一切責任を負わないものとします。`}</Text>

        <Title>第28条（免責および補償）</Title>
        <Text>{`　1. 提供者は、本サービスに事実上または法律上の瑕疵（正確性、完全性、永続性、目的適合性、有用性、適法性、安全性、信頼性、無害性、セキュリティ等に関する欠陥、エラーやバグ、権利侵害等を含みます。）がないことを保証するものではありません。また、契約者が提供者から直接または間接的に、本サービスに関する情報を得た場合であっても、提供者は、契約者に対して、本約款に規定されている内容を超えていかなる保証も行うものではありません。`}</Text>
        <Text>{`　2. 提供者は、契約者による本サービスの利用に起因する第三者の権利侵害が契約者の本約款違反から生じている場合には、当該権利侵害に関して一切の責任を負わないものとします。`}</Text>
        <Text>{`　3. 契約者は、契約者による本約款の違反の他、契約者による本サービスの利用に関連する法令または条例の違反に起因して生じる第三者からのクレーム、訴訟提起、または損害賠償請求につき、提供者を免責し、かつ、提供者に生じる損害を補償するものとします。`}</Text>
        <Text>{`　4. 提供者または販売店は、契約者に対して、本サービスを利用することにより生じる一切の損害について、その損害を賠償する責任を負いません。ただし、提供者または販売店に故意または重過失がある場合はこの限りでありません。`}</Text>
        <Text>{`　5. 前項但書の場合において、提供者または販売店が契約者に対して負う賠償責任の総額は、理由の如何を問わず、当該損害の発生時点から遡って1年分の対価の総額を超えないものとします。`}</Text>

        <Title>第29条（利用約款の変更）</Title>
        <Text>{`提供者は、契約者の承諾なく本約款を変更することができるものとし、本サービスについては、変更後の約款が適用されるものとします。変更の場合は、提供者が、電子メールによる送信、提供者が運営するホームページへの掲載その他提供者が適当であると判断する方法により、契約者に随時必要な事項を通知するものとします。`}</Text>

        <Title>第30条（損害賠償）</Title>
        <Text>{`提供者は、契約者が次の各号のいずれかに該当した場合には、契約者に対して損害賠償請求を行うことがあります。`}</Text>
        <Text>{`　(1) 契約者がF5のDoS攻撃や、ある種のプログラムを使って過負荷をかけるなど、故意に本サービス用設備へ過大な負荷をかけるなどの操作を行った場合`}</Text>
        <Text>{`　(2) 本サービス用のシステムを解析するなどの不正使用を行った場合`}</Text>
        <Text>{`　(3) その他本約款に違反する行為により提供者が損害を受けた場合`}</Text>

        <Title>第31条（通知等）</Title>
        <Text>{`　1. 本サービスに関する提供者から契約者への通知および連絡は、電子メールによる送信、提供者が運営するホームページへの掲載その他提供者が適当であると判断する方法により行うものとします。`}</Text>
        <Text>{`　2. 本サービスに関する契約者から提供者への通知および連絡は、提供者が運営するホームページ内の適宜の場所に設置するお問い合わせフォームの送信または提供者が指定する方法により行うものとします。`}</Text>

        <Title>第32条（分離可能性）</Title>
        <Text>{`本約款のいずれかの規定の全部またはその一部が、法令等または裁判所により違法、無効または不能であると判断された場合においても、本約款の残りの規定、および一部が違法、無効または不能であると判断された規定の残りの部分は、引き続き有効に存続するものとします。`}</Text>

        <Title>第33条（管轄裁判所）</Title>
        <Text>{`契約者と提供者との間の一切の紛争については、東京簡易裁判所または東京地方裁判所を第一審の専属的合意管轄裁判所とします。`}</Text>

        <Text style={{ marginTop: '1.5rem' }}>{`附則`}</Text>
        <Text>{`本約款は、2021年5月21日から施行します`}</Text>
        <Text>{`改定：2021年8月4日`}</Text>
        <Text>{`改定：2023年5月15日`}</Text>
        <Text>{`改定：2024年3月25日`}</Text>
        <Text>{`改定：2025年1月6日`}</Text>
      </Container>
    </SimplePageLayout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
  background-color: ${colors.white};
  white-space: normal;
`

const Title = styled.div`
  font-size: 1.1rem;
  font-weight: ${layout.boldFontWeight};
  margin-top: 2rem;
  margin-bottom: 0.3rem;
`

const Text = styled.div`
  max-width: 100%;
  min-width: 100%;
  word-break: break-all;
  line-height: 1.5rem;
  font-size: 1rem;
`
