import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '../../styleConstants'
import { UserCircle } from '@styled-icons/fa-solid/UserCircle'
import { OpenInNew } from '@styled-icons/material/OpenInNew'

interface Props {
  readonly name: string
  readonly admin?: boolean
  readonly naviOpened?: boolean // ナビゲーションの開閉状態
  readonly toAccount: () => void
  readonly toAdmin?: () => void
  readonly logout: () => void
  readonly onClose: () => void
}

export function AccountMenu({ name, admin, naviOpened, toAccount, toAdmin, logout, onClose }: Props) {
  const ref = React.useRef<HTMLDivElement>(null)
  // 範囲外をクリックすることで閉じる
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose()
      event.stopPropagation()
    }
  }
  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })
  return (
    <Container ref={ref} style={{ left: naviOpened ? '200px' : '40px' }}>
      <Box>
        <div style={{ width: '24px', marginRight: '0.5rem' }}>
          <User />
        </div>
        <NameBox>{name}</NameBox>
      </Box>

      <Links>
        <DesignedLink
          onClick={toAccount}
          href="/account"
          target="_blank"
          rel="noopener"
          style={{ display: 'flex', height: '18px' }}
        >
          <div style={{ display: 'flex', alignItems: 'center', height: '100%', marginRight: '0.5rem' }}>
            {`アカウント情報`}
          </div>
          <OpenInNew size={16} color={`${colors.gray600}`} />
        </DesignedLink>
        {admin && (
          <DesignedLink
            onClick={toAdmin}
            href="/admin/info"
            target="_blank"
            rel="noopener"
            style={{ display: 'flex', height: '18px' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                marginRight: '0.5rem',
              }}
            >{`組織管理`}</div>
            <OpenInNew size={16} color={`${colors.gray600}`} />
          </DesignedLink>
        )}
        <DesignedLink onClick={logout}>ログアウト</DesignedLink>
      </Links>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 130px;
  bottom: 35px;
  width: 220px;
  background-color: ${colors.white};
  box-shadow: 0 0 4px ${colors.gray400};
  z-index: ${layout.popupZIndex};
`

const Box = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid ${colors.gray300};
`

const NameBox = styled.div`
  font-weight: bold;
  word-break: break-all;
`

const User = styled(UserCircle).attrs({
  size: '24',
  color: `${colors.lightBlue}`,
})`
  margin-right: 0.5rem;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
`

const DesignedLink = styled.a`
  color: ${colors.link.base};
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.link.hover};
    font-weight: 600;
  }

  &:visited {
    color: ${colors.link.visited};
  }

  & + & {
    margin-top: 1.5rem;
  }
`
