import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { ContextType, useContextState } from './state'
import { CustomFilterContext } from '../CustomFilterContext'

export const CustomDimensionValueReportContext = React.createContext<ContextType>(null as any)

interface Props extends RouteComponentProps {
  readonly children?: React.ReactNode | React.ReactNode[]
}

/**
 * カスタムディメンションバリューレポートの state, actions をグローバルで使用できるよう Provider で定義する
 *
 * @param {Object} props - The props object containing the children component.
 * @param {React.ReactNode} props.children - The child component(s) to be wrapped.
 */
export function CustomDimensionValueReportProvider({ children }: Props) {
  const { isReportParamsChanged } = React.useContext(CustomFilterContext)

  const { state, actions } = useContextState()

  React.useEffect(() => {
    // 流入レポート->ページレポート遷移後にレポート共通パラメータがリセットされた場合、
    // 一覧件数や順番が変わる可能性があるためページャーをリセットする
    if (isReportParamsChanged) {
      actions.resetPagerIndex()
    }
  }, [isReportParamsChanged])

  return (
    <CustomDimensionValueReportContext.Provider value={{ state, actions }}>
      {children}
    </CustomDimensionValueReportContext.Provider>
  )
}
