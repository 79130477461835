import * as React from 'react'
import styled from 'styled-components'

import { colors } from '../../styleConstants'

interface HelpLinkProps {
  text: string
  href: string
}

/**
 * ヘルプツールチップのリンクテキスト用のコンポーネント
 * ヘルプのツールチップは背景色が暗めの青のため、ヘルプ内のリンクは明るめの色を採用している
 */
export const HelpTooltipLinkText = ({ text, href }: HelpLinkProps) => {
  return (
    <HelpLink href={href} target="_blank" rel="noopener">
      {text}
    </HelpLink>
  )
}

const HelpLink = styled.a`
  color: ${colors.contentBlue.blue3};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
