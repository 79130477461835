import * as React from 'react'

import { SortKey } from '../../../util/hooks/api/FunnelReport/types'
import { ProjectContext } from '../../ProjectRoot'
import { DataTable, DataTd, DataTh, DataTr, SortControl } from '../../../components/DataTable'
import { DataContentLink } from '../../../components/DataTable/DataContentLink'
import { SORT_KEYS } from '../../../util/hooks/api/FunnelReport/constants'
import { SORT_ICONS, SortIconState } from '../../../components/common/SortButton'
import { FunnelReportContext } from '../../../contexts/FunnelReportContext'
import { Authority } from '../../../util/Authority'
import { GlobalContext } from '../../../GlobalState'
import { DeleteModal } from '../DeleteModal'
import { useFunnelReports } from '../../../util/hooks/api/FunnelReport/useFunnelReports'
import { SettingsIconWithPopupMenus } from '../../../components/DataTable/SettingsIconWithPopupMenus'
import { navigate } from '@gatsbyjs/reach-router'
import { CreateLocationState } from '../../FunnelReport/createPage'

/**
 * ファネルレポート一覧テーブルをレンダリングするコンポーネント
 */
export function ReportsTable() {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)

  const {
    state: { projectId, baseUrl },
  } = React.useContext(ProjectContext)

  const { sortKey, sortIcon, setSortKey, setSortIcon } = React.useContext(FunnelReportContext)

  const [isDeleteModalOpened, setIsDeleteModalOpened] = React.useState(false)
  const [reportIdToDelete, setReportIdToDelete] = React.useState<number | null>(null)

  const dataMenusPopupRef = React.useRef<any>()
  const closeDataMenusPopup = () => dataMenusPopupRef.current?.close()

  const { data } = useFunnelReports({ projectId })

  if (!data) return null
  const reports = data.results

  const handleDuplicateReport = (funnelReportId: number) => {
    closeDataMenusPopup()
    const state: CreateLocationState = { duplicateFunnelReportId: funnelReportId }
    navigate(`/projects/${projectId}/report/funnel/new`, {
      state,
    })
  }

  const handleDeleteReport = (funnelReportId: number) => {
    closeDataMenusPopup()
    setIsDeleteModalOpened(true)
    setReportIdToDelete(funnelReportId)
  }

  const getSortIcon = (value: SortKey): SortIconState => {
    return sortKey === value ? sortIcon : SORT_ICONS.NONE
  }

  const setSortValues = (newSortKey: string, newSortIcon: SortIconState): void => {
    setSortKey(newSortKey as SortKey)
    setSortIcon(newSortIcon)
  }

  const { search } = window.location

  const canAdd = !data.reportLimitReached && Authority.canAddFunnelReport(AccountInfo.permissions, projectId)
  const canDelete = Authority.canDeleteFunnelReport(AccountInfo.permissions, projectId)
  const isSettingsIconVisible = canAdd || canDelete

  return (
    <>
      <DataTable>
        <thead>
          <DataTr>
            <DataTh noBorderRight minWidth="52px"></DataTh>
            <DataTh fillWidth textAlign="left">
              <SortControl
                sortKey={SORT_KEYS.NAME}
                sortIcon={getSortIcon(SORT_KEYS.NAME)}
                onSortClick={setSortValues}
                textAlign="left"
              >
                ファネルレポート名
              </SortControl>
            </DataTh>

            <DataTh minWidth="126px" textAlign="center">
              <SortControl
                sortKey={SORT_KEYS.UPDATED_AT}
                sortIcon={getSortIcon(SORT_KEYS.UPDATED_AT)}
                onSortClick={setSortValues}
              >
                最終更新日時
              </SortControl>
            </DataTh>

            <DataTh minWidth="154px" textAlign="center">
              <SortControl
                sortKey={SORT_KEYS.UPDATED_USER}
                sortIcon={getSortIcon(SORT_KEYS.UPDATED_USER)}
                onSortClick={setSortValues}
              >
                最終更新ユーザー名
              </SortControl>
            </DataTh>

            {isSettingsIconVisible && <DataTh minWidth="56px" />}
          </DataTr>
        </thead>
        <tbody>
          {reports.map((item, index) => (
            <DataTr key={item.id}>
              <DataTd noBorderRight verticalAlign="top">
                {index + 1}
              </DataTd>

              <DataTd textAlign="left" whiteSpace="normal">
                {data.reportLimitExceeded && <>{item.name}</>}
                {!data.reportLimitExceeded && (
                  <DataContentLink to={`${baseUrl}report/funnel/${item.id}${search}`}>{item.name}</DataContentLink>
                )}
              </DataTd>

              <DataTd textAlign="left">{item.updatedAt}</DataTd>

              <DataTd textAlign="left" ellipsis tooltipContent={item.updatedUserName}>
                {item.updatedUserName}
              </DataTd>

              {isSettingsIconVisible && (
                <DataTd textAlign="center">
                  <SettingsIconWithPopupMenus
                    popupRef={dataMenusPopupRef}
                    popupMenus={[
                      {
                        label: 'コピー',
                        hidden: !canAdd,
                        onClick: (e) => {
                          e?.preventDefault()
                          handleDuplicateReport(item.id)
                        },
                      },
                      {
                        label: '削除',
                        hidden: !canDelete,
                        onClick: (e) => {
                          e?.preventDefault()
                          handleDeleteReport(item.id)
                        },
                      },
                    ]}
                  />
                </DataTd>
              )}
            </DataTr>
          ))}
        </tbody>
      </DataTable>

      <DeleteModal
        opened={isDeleteModalOpened}
        funnelReportId={reportIdToDelete}
        onClose={() => setIsDeleteModalOpened(false)}
      />
    </>
  )
}
