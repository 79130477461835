import * as React from 'react'
import { DataTd } from '../../../components/DataTable'
import { TrafficReportItemData } from '../../../util/hooks/api/TrafficReport/types'
import { useTrafficReportDimensionChoices } from '../../../util/hooks/cookie/useTrafficReportDimensionChoices'
import { DIMENSION_TABLE_CONFIG } from './'

interface Props {
  readonly item: TrafficReportItemData
}

/**
 * 流入レポートの各ディメンションのセルをレンダリングする
 *
 * @param {Props} param - The input parameters for the method.
 * @param {TrafficReportItemData} param.item - 流入レポートのアイテムデータ
 * @returns {JSX.Element[]} 各ディメンションのセルを含むJSX要素の配列
 */
export const DimensionCells = ({ item }: Props) => {
  const { dimensionChoices } = useTrafficReportDimensionChoices()

  return (
    <>
      {dimensionChoices.map((dimensionId) => {
        const value = DIMENSION_TABLE_CONFIG[dimensionId].getItemData(item)
        const displayValue = value || '-'

        return (
          <DataTd key={dimensionId} textAlign="left" ellipsis tooltipContent={displayValue}>
            {displayValue}
          </DataTd>
        )
      })}
    </>
  )
}
