import * as React from 'react'
import styled from 'styled-components'
import { RefObject } from 'react'
import { GlobalContext } from '../../../GlobalState'
import { Authority } from '../../../util/Authority'
import { ReportTable } from '../ReportTable'
import { SearchArea } from './SearchArea'
import { makeItemsNotExistsMessage } from '../../../util/hooks/api/CustomDimensionReport/utils'
import { Loading } from '../../../components/common/Loading'
import { ProjectContext } from '../../ProjectRoot'
import { CustomFilterContext } from '../../../contexts/CustomFilterContext'
import { CanNotViewMessage } from '../../../components/warnings/CanNotViewMessage'
import { ErrorBox } from '../../../components/common/ErrorBox'
import { WarningBox } from '../../../components/warnings/WarningBox'
import { CustomDimensionValueReportContext } from '../../../contexts/CustomDimentionValueReportContext'
import { scrollTop } from '../../../util/scrollToElement'
import { UrlParamsErrorMessages } from '../../../components/reportMessages/UrlParamsErrorMessages'
import { NotSettingsMessages } from '../../../components/reportMessages/NotSettingsMessages'
import { useReportSettings } from '../../../util/hooks/useReportSettings'
import { useCustomDimensionValueReport } from '../../../util/hooks/api/CustomDimensionReport/useCustomDimensionValueReport'
import { Paginate } from '../../../components/common/Paginate'
import { ListItemsCount } from '../../../components/ListItemsCount'
import {
  LIST_ITEMS_COUNT_CHOICES,
  ListItemsCountChoice,
} from '../../../contexts/CustomDimentionValueReportContext/state'

interface Props {
  readonly customDimensionId: number
  readonly mainAreaRef: RefObject<HTMLDivElement>
}

/**
 * カスタムディメンションレポート(下層)のレポートエリアをレンダリングする
 *
 * @param {object} param - The input parameters for the method.
 * @param {number} param.customDimensionId - カスタムディメンションID
 * @param {React.RefObject} param.mainAreaRef - MainAreaの参照オブジェクト（各ページのスクロール処理に使用）
 */
export function ReportArea({ customDimensionId, mainAreaRef }: Props) {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const {
    state: { projectId, baseUrl },
  } = React.useContext(ProjectContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)
  const {
    state: { searchText, listItemsCount, pagerIndex },
    actions: { setListItemsCount, setPagerIndex, resetPagerIndex },
  } = React.useContext(CustomDimensionValueReportContext)

  const canView = React.useMemo(
    () => Authority.canViewReport(AccountInfo.permissions, projectId),
    [AccountInfo.permissions, projectId],
  )

  const { isCompleted: isSettingsCompleted, isChecking: isSettingsChecking } = useReportSettings({
    projectId: projectId,
  })

  const { data, isLoading, isError, errorMessage } = useCustomDimensionValueReport({
    projectId,
    customDimensionId,
  })

  React.useEffect(() => scrollTop(mainAreaRef), [data])

  if (!canView) return <CanNotViewMessage />
  if (!isSettingsChecking && !isSettingsCompleted)
    return <NotSettingsMessages projectId={projectId} baseUrl={baseUrl} />
  if (isSettingsChecking || isLoading) return <Loading />

  const itemCountAll = data?.count || 0
  const itemCountFrom = itemCountAll > 0 ? pagerIndex * listItemsCount + 1 : 0
  const itemCountTo = Math.min((pagerIndex + 1) * listItemsCount, itemCountAll)
  const pageCount = Math.ceil(itemCountAll / listItemsCount)

  const warningMessage = data?.results.length === 0 ? makeItemsNotExistsMessage(searchText, customFilterState) : ''

  const handleListItemsCountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value) as ListItemsCountChoice
    setListItemsCount(value)
    resetPagerIndex()
  }

  const handlePaginateChange = (selectedItem: { selected: number }) => {
    setPagerIndex(selectedItem.selected)
  }

  return (
    <>
      <UrlParamsErrorMessages />
      {isError && !errorMessage && <ErrorBox>カスタムディメンションレポートの取得に失敗しました。</ErrorBox>}
      {errorMessage !== '' && <ErrorBox>{errorMessage}</ErrorBox>}
      {warningMessage !== '' && <WarningBox>{warningMessage}</WarningBox>}
      <ControlWrapper>
        <SearchArea onSearch={resetPagerIndex} onSearchClear={resetPagerIndex} />
        {itemCountAll > 0 && (
          <CountWrapperTop>
            <ListItemsCount
              listItemsCount={listItemsCount}
              choices={LIST_ITEMS_COUNT_CHOICES}
              itemCountAll={itemCountAll}
              itemCountFrom={itemCountFrom}
              itemCountTo={itemCountTo}
              onChange={handleListItemsCountChange}
            />
          </CountWrapperTop>
        )}
      </ControlWrapper>
      {itemCountAll > 0 && (
        <>
          <ReportTable reportData={data} />
          <CountWrapperBottom>
            <ListItemsCount
              listItemsCount={listItemsCount}
              choices={LIST_ITEMS_COUNT_CHOICES}
              itemCountAll={itemCountAll}
              itemCountFrom={itemCountFrom}
              itemCountTo={itemCountTo}
              onChange={handleListItemsCountChange}
            />
          </CountWrapperBottom>
          <PaginateWrapper>
            <Paginate
              forcePage={pagerIndex}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              disableInitialCallback={false}
              onPageChange={handlePaginateChange}
            />
          </PaginateWrapper>
        </>
      )}
    </>
  )
}

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
`

const CountWrapperTop = styled.div`
  margin-left: 30px;
`

const CountWrapperBottom = styled.div`
  margin: 17px 0 9px;
`

const PaginateWrapper = styled.div`
  display: flex;
`
