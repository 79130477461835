import * as React from 'react'
import styled from 'styled-components'

import { SearchText } from '../../../../components/textbox/SearchText'
import { CustomDimensionReportContext } from '../../../../contexts/CustomDimentionReportContext'

/**
 * 検索テキスト・ページキャプチャ対象ページ絞り込みなどの検索エリアをレンダリングする
 */
export function SearchArea() {
  const {
    state: { searchText },
    actions: { setSearchText },
  } = React.useContext(CustomDimensionReportContext)

  const handleSearch = (value: string) => setSearchText(value)
  const handleSearchClear = () => setSearchText('')

  return (
    <Container>
      <SearchWrapper>
        <SearchText value={searchText} onClear={handleSearchClear} onSearch={handleSearch} />
      </SearchWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 17px;
`

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  min-width: 416px;
`
