import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { colors } from '../styleConstants'
import { GlobalContext } from '../GlobalState'
import { Authority } from '../util/Authority'
import { usePageState } from './CaptureControl/state'
import { CaptureControlModals } from './CaptureControl/CaptureControlModals'
import { PageLayout } from '../components/layout/PageLayout'
import { Loading } from '../components/common/Loading'
import { PageHeader } from '../components/common/PageHeader'
import { Input } from '../components/common/Input'
import { AddButton } from '../components/common/Button'
import { CaptureControlGrid } from '../components/grid/CaptureControlGrid'
import { gridSize } from '../components/grid/CaptureControlGridItem'
import { HelpLink } from '../components/common/HelpLink'
import { WarningBox } from '../components/warnings/WarningBox'
import { ErrorBox } from '../components/common/ErrorBox'
import { HELP_LINKS } from '../constants'
import { CheckCircle } from 'styled-icons/boxicons-regular'
import { CloseCircle } from '@styled-icons/ionicons-sharp/CloseCircle'
import { CameraFill } from '@styled-icons/bootstrap/CameraFill'
import { makeProjectPageHeaderInfo } from '../util/makePageHeaderInfo'

const PAGE_TITLE = 'ページキャプチャ｜自動クローラー設定'
interface CaptureControlProps extends RouteComponentProps {
  projectId?: string
}

export function CaptureControl(props: CaptureControlProps) {
  const {
    state: { AccountInfo, PlanInfo },
    actions: globalActions,
  } = React.useContext(GlobalContext)
  const { state: pageState, actions } = usePageState()
  const {
    loading,
    reload,
    items,
    totalCount,
    totalEstimate,
    limitCount,
    isLimited,
    selectedId,
    sortState,
    isSearch,
    isScroll,
    matchedId,
    searchText,
    listRef,
    edit,
    errorMessage,
  } = pageState

  React.useEffect(() => {
    if (loading) {
      actions.fetch(props.projectId!)
    }
  }, [])
  React.useEffect(() => {
    if (reload) {
      actions.refetch()
      // 権限更新
      globalActions.updatePermissions()
    }
  }, [reload])
  React.useEffect(() => {
    if (edit.loading) {
      actions.calc()
    }
  }, [edit.loading])

  React.useEffect(() => {
    if (isScroll) {
      const $currentItem = items.find((item) => item.id === matchedId)?.itemRef?.current
      const $list = listRef?.current
      if ($currentItem && $list) {
        // MEMO: 385pxはウィンドウ上辺からヘッダーまでの高さ
        const scrollTo = $currentItem.getBoundingClientRect().top + $list.scrollTop - 385
        $list.scrollTo({ behavior: 'smooth', top: scrollTo })
      }
      actions.onReset!()
    }
  }, [isScroll])

  // 検索実行
  const onSearch = (event: any) => {
    event.preventDefault()
    if (actions.validate()) {
      actions.search()
      // グローバルステータスに保存
      globalActions.setCaptureControlSearchText(searchText)
    }
  }
  // 検索条件クリア
  const onClear = () => {
    actions.clear()
    globalActions.setCaptureControlSearchText('')
  }

  const pageHeaderInfo = makeProjectPageHeaderInfo(PAGE_TITLE, AccountInfo)

  return (
    <div>
      <PageHeader title={pageHeaderInfo.title} description={pageHeaderInfo.description} />
      {(loading || reload) && <Loading />}
      <CaptureControlModals state={pageState} actions={actions} />

      <PageLayout
        headerTitle={PAGE_TITLE}
        optionHidden={true}
        filterButtonHidden={true}
        baseUrl={props.uri?.split(props.path!).join('')}
      >
        {!Authority.canViewPageCapture(AccountInfo.permissions, props.projectId!) && (
          <div style={{ margin: '1rem 0 0 1rem' }}>閲覧権限がありません。</div>
        )}
        {!loading && !reload && Authority.canViewPageCapture(AccountInfo.permissions, props.projectId!) && (
          <Container>
            <div>
              {/* メッセージ表示 */}
              {(isLimited || errorMessage || !PlanInfo.canTrackContentEvent) && (
                <Box style={{ minWidth: '1100px' }}>
                  {!PlanInfo.canTrackContentEvent && (
                    <WarningBox>
                      <div>
                        現在のプランでは、コンテンツレポートの計測が停止しているため、ページキャプチャは実行されません。
                      </div>
                      <div>コンテンツレポートの計測を開始したい場合は、ご契約プランをアップしてください。</div>
                    </WarningBox>
                  )}
                  {PlanInfo.canTrackContentEvent && isLimited && (
                    <WarningBox>
                      <div>月間キャプチャ数予測が上限を超えています。</div>
                      <div>
                        ご契約プランをアップするか、「月間ページキャプチャ数予測」が「月間キャプチャ上限数」を超えないように、自動キャプチャ頻度を見直すことを推奨します。
                      </div>
                      <div>
                        <span>{`（上限を超えても、ページキャプチャは取得し続けます。詳しくは `}</span>
                        <DesignedLink href={HELP_LINKS.CAPTURE_CONTROL_HOW_TO} target="_blank" rel="noopener">
                          こちら
                        </DesignedLink>
                        <span>{` をご覧ください。）`}</span>
                      </div>
                    </WarningBox>
                  )}
                  {/* 汎用エラーメッセージ */}
                  {errorMessage && <ErrorBox>{errorMessage}</ErrorBox>}
                </Box>
              )}

              <Box>
                <Layer style={{ marginBottom: '1rem', minWidth: '1150px' }}>
                  {/* 説明文 */}
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem' }}>
                    <ExpLabel>
                      ページキャプチャとは、Content&nbsp;Analyticsのクローラーが対象ページを訪問・コンテンツを取得し、計測を開始することを言います。
                    </ExpLabel>
                    <ExpLabel>下記より計測対象のページURLを設定してください。</ExpLabel>
                    <HelpLinkBox>
                      <HelpLink
                        title={'自動クローラー設定とは'}
                        link={HELP_LINKS.CAPTURE_CONTROL_HOW_TO}
                        style={{ marginLeft: '0' }}
                      />
                    </HelpLinkBox>
                  </div>
                </Layer>

                <Layer style={{ marginBottom: '1rem' }}>
                  {/* 権限による表示制限あり */}
                  {Authority.canViewAddPageCapture(AccountInfo.permissions, props.projectId!) && (
                    <AddButton
                      // プランによる制限あり
                      disabled={!Authority.canAddPageCapture(AccountInfo.permissions, props.projectId!)}
                      onClick={
                        Authority.canAddPageCapture(AccountInfo.permissions, props.projectId!)
                          ? actions.edit.onAdd
                          : () => alert('現在のプランではご利用いただけません')
                      }
                    >
                      ページを追加
                    </AddButton>
                  )}
                </Layer>

                {/* 検索 */}
                <Layer style={{ marginBottom: '1rem' }}>
                  <form onSubmit={onSearch}>
                    <DesignedInputWrapper>
                      <DesignedInputLabel>計測対象ページ確認：</DesignedInputLabel>
                      <DesignedInput
                        type="text"
                        value={searchText}
                        iconRight={
                          isSearch ? (
                            <div style={{ display: 'flex' }}>
                              <ClearIcon style={{ marginRight: '0.5rem' }} onClick={onClear} />
                              <SearchIcon onClick={onSearch} />
                            </div>
                          ) : (
                            <SearchIcon onClick={onSearch} />
                          )
                        }
                        onChange={actions.onUpdate}
                        placeholder="計測対象ページの取得条件を確認することができます"
                      />
                    </DesignedInputWrapper>
                  </form>
                  <CaptureBox style={{ justifyContent: 'space-between' }}>
                    {false && <CameraFill size={28} color={colors.black} />}
                    <span style={{ fontSize: '0.8rem' }}>{`月間キャプチャ上限数`}</span>
                    <span style={{ fontWeight: 'bold' }}>
                      {limitCount > 0 ? `約 ${limitCount.toLocaleString()}` : 0}
                    </span>
                  </CaptureBox>
                </Layer>

                {items.length > 0 && (
                  <CaptureControlGrid
                    items={items}
                    totalCount={totalCount}
                    totalEstimate={totalEstimate}
                    selectedId={selectedId}
                    sortState={sortState}
                    matchedId={matchedId}
                    canChange={Authority.canChangePageCapture(AccountInfo.permissions, props.projectId!)}
                    canDelete={
                      Authority.canDeletePageCapture(AccountInfo.permissions, props.projectId!) && items.length > 1
                    } // データ数が１件の場合削除させない
                    onSort={actions.onSort}
                    onEdit={actions.edit.onEdit}
                    onDelete={actions.edit.onDelete}
                    isUnlimited={pageState.edit.isUnlimited}
                    listRef={listRef}
                  />
                )}
              </Box>
            </div>
          </Container>
        )}
      </PageLayout>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem;
  min-width: ${gridSize.minWidth};
  height: 100%;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${gridSize.minWidth};
  margin: 2rem 0;
`

const ExpLabel = styled.div`
  font-size: 1rem;
  color: ${colors.gray700};
`

const HelpLinkBox = styled.div`
  margin-top: 12px;
`

const CaptureBox = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  height: 48px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray300};
  padding: 0 1rem;
  margin-left: 1rem;
`

const DesignedInput = styled(Input)`
  width: 480px;
  height: 100%;
`

const DesignedInputWrapper = styled.div`
  background-color: ${colors.lightCyan};
  height: 48px;
  padding: 8px 12px;
  display: flex;
`
const DesignedInputLabel = styled.div`
  font-weight: bold;
  line-height: 32px;
`
const DesignedLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${colors.link.base};

  &:hover {
    color: ${colors.link.hover};
  }

  &:visited {
    color: ${colors.link.visited};
  }
`

const Layer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SearchIcon = styled(CheckCircle).attrs({
  size: 18,
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`

const ClearIcon = styled(CloseCircle).attrs({
  size: 18,
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`
