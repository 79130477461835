import * as React from 'react'
import { SORT_ICONS, SortIconState } from '../../components/common/SortButton'
import { SORT_KEYS } from '../../util/hooks/api/TrafficReport/constants'
import { SortKey } from '../../util/hooks/api/TrafficReport/types'

// 一覧表示件数のデフォルト値・選択肢を定義
const LIST_ITEMS_COUNT_DEFAULT = 20
export const LIST_ITEMS_COUNT_CHOICES = [20, 50, 100, 500, 1000] as const
export type ListItemsCountChoice = typeof LIST_ITEMS_COUNT_CHOICES[number]

export interface ContextType {
  readonly sortKey: SortKey
  readonly setSortKey: (value: SortKey) => void
  readonly sortIcon: SortIconState
  readonly setSortIcon: (value: SortIconState) => void
  readonly getSortIcon: (value: SortKey) => SortIconState
  readonly setSortValues: (newSortKey: string, newSortIcon: SortIconState) => void
  readonly listItemsCount: ListItemsCountChoice
  readonly setListItemsCount: (value: ListItemsCountChoice) => void
  readonly pagerIndex: number
  readonly setPagerIndex: (value: number) => void
  readonly resetPagerIndex: () => void
  readonly searchText: string
  readonly setSearchText: (value: string) => void
}

export function useContextState(): ContextType {
  const [sortKey, setSortKey] = React.useState<SortKey>(SORT_KEYS.SESSION_COUNT)
  const [sortIcon, setSortIcon] = React.useState<SortIconState>(SORT_ICONS.DOWN)
  const [listItemsCount, setListItemsCount] = React.useState<ListItemsCountChoice>(LIST_ITEMS_COUNT_DEFAULT)
  const [pagerIndex, setPagerIndex] = React.useState<number>(0)
  const [searchText, setSearchText] = React.useState<string>('')

  const getSortIcon = (value: SortKey) => {
    return sortKey === value ? sortIcon : SORT_ICONS.NONE
  }

  const setSortValues = (newSortKey: string, newSortIcon: SortIconState) => {
    setSortKey(newSortKey as SortKey)
    setSortIcon(newSortIcon)
  }

  const resetPagerIndex = () => {
    setPagerIndex(0)
  }

  return {
    sortKey,
    setSortKey,
    sortIcon,
    setSortIcon,
    getSortIcon,
    setSortValues,
    listItemsCount,
    setListItemsCount,
    pagerIndex,
    setPagerIndex,
    resetPagerIndex,
    searchText,
    setSearchText,
  }
}
