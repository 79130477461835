import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { ProjectContext } from '../ProjectRoot'
import { ReportPageLayout } from '../../components/layout/ReportPageLayout'
import { ReportArea } from './ReportArea'
import { useReportFilterSelects } from '../../util/hooks/api/Filter/useReportFilterSelects'
import { PAGE_TITLES } from '../../constants'
import { BreadcrumbsItemType } from '../../components/breadcrumbs/Breadcrumbs'
import { CUSTOMDIMENSION_REPORT_MAX_WIDTH } from '../CustomDimensionReport'
import { CustomDimensionValueReportContext } from '../../contexts/CustomDimentionValueReportContext'

interface Props
  extends RouteComponentProps<{
    customDimensionId: string
  }> {}

export function CustomDimensionValueReportPage(props: Props) {
  const customDimensionId: number = Number(props.customDimensionId)
  if (isNaN(customDimensionId)) return null

  const {
    state: { projectId, baseUrl },
  } = React.useContext(ProjectContext)
  const {
    actions: { resetPagerIndex },
  } = React.useContext(CustomDimensionValueReportContext)

  const { data: reportFilterSlectsData } = useReportFilterSelects({ projectId: projectId.toString() })

  const customDimensionName =
    reportFilterSlectsData?.customDimensions.find(
      (customDimension) => customDimension.customDimensionId === customDimensionId,
    )?.name || ''

  const { search } = window.location

  const breadcrumbsItems: BreadcrumbsItemType[] = [
    {
      text: PAGE_TITLES.CUSTOM_DIMENSION_REPORT,
      link: `${baseUrl}report/custom-dimension${search}`,
    },
  ]

  const mainAreaRef = React.useRef<HTMLDivElement>(null)

  return (
    <ReportPageLayout
      pageTitle={customDimensionName}
      breadcrumbsItems={breadcrumbsItems}
      mainAreaRef={mainAreaRef}
      reportAreaMaxWidth={`${CUSTOMDIMENSION_REPORT_MAX_WIDTH}px`}
      onDateRangeApply={resetPagerIndex}
      onGoalChange={resetPagerIndex}
      onFilterReset={resetPagerIndex}
      onFilterApply={resetPagerIndex}
      onDeviceChange={resetPagerIndex}
      onScopeTypeChange={resetPagerIndex}
    >
      <ReportArea customDimensionId={customDimensionId} mainAreaRef={mainAreaRef} />
    </ReportPageLayout>
  )
}
