import * as React from 'react'
import { ScopeType } from '../../util/hooks/useScopeType'
import { DeviceType } from '../../util/hooks/useDeviceType'
import { CalenderState } from '../../components/common/DayPickerRange'
import { CustomFilterState } from '../../util/hooks/api/Filter/types'

// 一覧表示件数のデフォルト値・選択肢を定義
const LIST_ITEMS_COUNT_DEFAULT = 20
export const LIST_ITEMS_COUNT_CHOICES = [20, 50, 100, 500, 1000] as const
export type ListItemsCountChoice = typeof LIST_ITEMS_COUNT_CHOICES[number]

interface CachedReportParams {
  readonly calenderState: CalenderState
  readonly goalId: number
  readonly scopeType: ScopeType
  readonly deviceType: DeviceType
  readonly customFilterState: CustomFilterState[]
  readonly filterContentEventsExists: boolean
}

export interface ContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly searchText: string
  readonly listItemsCount: ListItemsCountChoice
  readonly pagerIndex: number
  readonly cachedCustomDimensionId: number | undefined
  readonly cachedReportParams: CachedReportParams | undefined
}

const initialState: State = {
  searchText: '',
  listItemsCount: LIST_ITEMS_COUNT_DEFAULT,
  pagerIndex: 0,
  cachedCustomDimensionId: undefined,
  cachedReportParams: undefined,
}

interface Actions {
  setSearchText: (value: string) => void
  setListItemsCount: (value: ListItemsCountChoice) => void
  setPagerIndex: (value: number) => void
  resetPagerIndex: () => void
  resetSearchValues: (customDimensionId: number) => void
}

/**
 * カスタムディメンションレポート系画面で使用する state と actions を返す
 *
 * @return {ContextType} The context state and actions.
 */
export function useContextState(): ContextType {
  const [state, setState] = React.useState<State>(initialState)

  const setSearchText = (value: string): void => {
    setState((prevState) => ({
      ...prevState,
      searchText: value,
    }))
  }

  const setListItemsCount = (value: ListItemsCountChoice): void => {
    setState((prevState) => ({
      ...prevState,
      listItemsCount: value,
    }))
  }

  const setPagerIndex = (value: number): void => {
    setState((prevState) => ({
      ...prevState,
      pagerIndex: value,
    }))
  }

  const resetPagerIndex = (): void => {
    setState((prevState) => ({
      ...prevState,
      pagerIndex: 0,
    }))
  }

  /**
   * 検索情報をリセットする
   * 前回と検索時のカスタムディメンションが同一かを判定するため、カスタムディメンションIDをキャッシュする
   * @param {number} customDimensionId - The ID of custom dimension.
   */
  const resetSearchValues = (customDimensionId: number): void => {
    setState((prevState) => ({
      ...prevState,
      searchText: '',
      listItemsCount: LIST_ITEMS_COUNT_DEFAULT,
      pagerIndex: 0,
      cachedCustomDimensionId: customDimensionId,
    }))
  }

  return {
    state,
    actions: {
      setSearchText,
      setListItemsCount,
      setPagerIndex,
      resetPagerIndex,
      resetSearchValues,
    },
  }
}
